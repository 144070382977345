import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const styles = {
  drawer: {
      minWidth: 350,
      height: 82,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
  },
};

class DefaultDrawer extends React.Component {

  render() {
    const { classes } = this.props;
    const name = this.props.name;
    const onClose = this.props.onClose;
    const content = this.props.content;
    return (
      <div>
        <Drawer
            variant="temporary"
            open={this.props.open}
            anchor="right"
        >
          <div className={classes.drawer}>
              <IconButton onClick={onClose} style={{margin: '0 10px'}}>
                  <ChevronLeftIcon/>
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                {name}
              </Typography>
          </div>
          <Divider/>
          {content}
        </Drawer>
      </div>
    );
  }
}

DefaultDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DefaultDrawer);
  