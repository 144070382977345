import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MaterialTable from 'material-table';

const styles = theme => ({
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
});


class Table extends Component {
    render() {
        const {title, grouping, columns, data, onAdd, onUpdate, onDelete} = this.props;
        return (
            <MaterialTable
                title={title === undefined ? '' : title}
                columns={columns}
                data={data}
                options={{
                    paging: false,
                    grouping: grouping
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                onAdd(newData)
                                resolve()
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                onUpdate(newData, oldData);
                                resolve()
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                onDelete(oldData);
                                resolve()
                            }, 1000)
                        }),
                }}
            />
        );
    }
}

export default withStyles(styles)(Table)
