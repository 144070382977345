import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import UserCard from './../../components/UserCard';
import {Formik} from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import DefaultDrawer from "../../components/Drawer";
import Checkbox from '@material-ui/core/Checkbox';
import AddButton from '../../components/AddButton';

//Styles:
import './../../styles/dashboard.css';
import {NavLink} from "react-router-dom";

let api_base = process.env.REACT_APP_API_URL;

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        paddingLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerContainer: {
        padding: 20
    },
    formGroup: {
        padding: 10
    },
    formControl: {
        width: '100%'
    },
    formLogo: {
        maxWidth: 150
    }
});

class UserForm extends Component {
    render() {
        let classes = this.props.classes;
        let initialValues = {
            'name': '',
            'email': '',
            'password': ''
        };
        if (this.props.user !== undefined) {
            Object.keys(this.props.user).map(key => initialValues[key] = this.props.user[key]);
        }
        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        this.props.handleSubmit(JSON.stringify(values, null, 2), initialValues.id);
                        actions.setSubmitting(false);
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>

                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Name</FormLabel>
                                    <TextField
                                        type="text"
                                        placeholder="John Doe"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        name="name"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Email</FormLabel>
                                    <TextField
                                        name="email"
                                        type="email"
                                        placeholder="name@domain.tld"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Password</FormLabel>
                                    <TextField
                                        name="password"
                                        type="password"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password}
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Phone number</FormLabel>
                                    <TextField
                                        name="phone_number"
                                        type="text"
                                        placeholder="+1234567890"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.phone_number}
                                    />
                                </FormGroup>

                                <FormGroup className={classes.formGroup}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value="{props.values.send_invite}"
                                                color="primary"
                                            />
                                            }
                                        label="Send invitation via email"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.fromGroup}>
                                    {console.log(this.props.user)}
                                    <Button type="submit" variant="contained"
                                        color="primary">{this.props.user['id'] === undefined ? 'Submit' : 'Update'}</Button>
                                </FormGroup>
                            </FormControl>

                        </form>
                    )}
                />

            </div>
        )
    }
}

class Client extends Component {
    state = {
        showAlertDialog: false,
        client: {},
        user: '',
        users: [],
        sideOpen: false,
        selectedUser: {}
    };

    handleAlertDialog = (id) => {
        this.setState({
            showAlertDialog: !this.state.showAlertDialog,
            dialogId: id
        })
    };

    setUserId = (id) => {
        let user = this.state.users.find((item) => item.id === id);
        this.setState({
            selectedUser: user,
            sideOpen: true
        });
    };

    removeUserById = () => {
        fetch(`${api_base}/users/${this.state.dialogId}`, {
            method: 'DELETE',
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/users/?client=${this.props.match.params.id}`)
                    .then(response => response.json())
                    .then(data => this.setState({
                        users: data.body,
                        showAlertDialog: false
                    }));
            }
        )
    };

    componentDidMount() {
        fetch(`${api_base}/clients/${this.props.match.params.id}`)
            .then(response => response.json())
            .then(data => this.setState({client: data.body}));

        fetch(`${api_base}/users/?client=${this.props.match.params.id}`)
            .then(response => response.json())
            .then(data => this.setState({users: data.body}));
    }

    updateUser = (data, id) => {
        fetch( id === undefined ? `${api_base}/users/?client=${this.props.match.params.id}` :`${api_base}/users/${id}`, {
            method: id === undefined ? 'POST' : 'PUT',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/users/`)
                    .then(response => response.json())
                    .then(data => this.setState({
                        clients: data.body,
                        sideOpen: false
                    }));
            }
        )
    };

    toggleDrawer = (open, user) => () => {
        this.setState({
            selectedUser: user,
            sideOpen: open
        });
    };

    render() {
        const {classes} = this.props;
        let client = this.state.client;
        //let users = this.state.users;
        return (
            <React.Fragment>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    {/*Header Component*/}
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <NavLink to={`/clients/`}>
                                <IconButton aria-label="Clients" style={{margin: '0 10px 0 0'}}>
                                    <ArrowBack/>
                                </IconButton>
                            </NavLink>
                            <Typography variant="h5">
                                {client.name}
                            </Typography>
                            <div style={{flexGrow: 1, textAlign: 'right'}}>
                                <AddButton
                                    name='Add user'
                                    onClick={this.toggleDrawer(true, {})}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {/*Right sidebar*/}
                <DefaultDrawer
                    open={this.state.sideOpen}
                    name={this.state.selectedUser['id'] === undefined ? 'Add user' : 'Edit user'}
                    onClose={this.toggleDrawer(false, {})}
                    content={
                        <div className={classes.drawerContainer}>
                            <UserForm classes={this.props.classes} user={this.state.selectedUser}
                                handleSubmit={this.updateUser} connections={this.state.connections}/>
                        </div>
                    }
                />
                {/*Dialog Component*/}
                <Dialog
                    open={this.state.showAlertDialog}
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm deleting a user"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            When deleting a user, all the user data will be lost.<br/>
                            Are you sure you want to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertDialog} color="primary" autoFocus>
                            Discard
                        </Button>
                        <Button onClick={this.removeUserById} color="primary">
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*Users*/}
                {
                    <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                        <Grid item xs={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h5" style={{flexGrow: 1}}>
                                    Users ({this.state.users.length})
                                </Typography>
                                <div style={{textAlign: 'right'}}>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.users.map((user) => {
                                return (
                                    <UserCard user={user}
                                                showDialog={this.handleAlertDialog.bind(this, user.id)}
                                                setUserId={this.setUserId}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Client);