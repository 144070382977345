import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import DashboardList from './../../scenes/DashboardList';
import Dashboard from './../../scenes/DashboardSingle';
import Connections from './../../scenes/Connections';
import Clients from './../../scenes/Clients';
import Client from './../../scenes/Client';
import TopRightNav from "./../../components/Top/TopRightNav";
import Settings from "./../../scenes/Settings";
import UserSettings from "./../../scenes/UserSettings";
import GeneralIcon from "./../../components/Icons/GeneralIcons";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import {NavLink} from 'react-router-dom'
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';

const drawerWidthOpen = 295;
const drawerWidthClosed = 83;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    appBar: {
        height: theme.overrides.MuiToolbar.regular.height,
        'z-index': 'auto',
        'box-shadow': 'none',
    },
    topBar: {
        display: 'flex',
        transition: 'all .3s ease-in-out',
        '-webkit-box-shadow': 'inset 0px -12px 20px -9px rgba(0,0,0,0.16)',
        '-moz-box-shadow': 'inset 0px -12px 20px -9px rgba(0,0,0,0.16)',
        'box-shadow': 'inset 0px -12px 20px -9px rgba(0,0,0,0.16)',
    },
    riLogo: {
        width: '27px',
    },
    logo: {
        height: theme.overrides.MuiToolbar.regular.height,
        width: 83,
        background: theme.palette.primary.main,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-around',
        position: 'fixed'
    },
    logo_text: {
        color: theme.palette.primary.main,
        'font-family': 'Lato, sans-serif',
        'text-transform': 'uppercase',
        'font-weight': '900',
        'font-style': 'italic',
        'letter-spacing': '0.6px',
        'font-size': '21px',
        'padding-left': '20px',
        'flex-grow': '1',
        'margin-left': theme.overrides.MuiToolbar.regular.height,
    },
    drawer: {
        marginTop: theme.overrides.MuiToolbar.regular.height,
        overflow: 'hidden',
        height: 'calc(100% - 82px)',
        background: 'linear-gradient(163deg, #000000 1%, #313131 100%)',
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    drawerPaper: {
        width: drawerWidthClosed,
    },
    drawerPaperOpen: {
        width: drawerWidthOpen,
    },
    backgroundForIcons: {
        content: '',
        display: 'block',
        position: 'absolute',
        width: theme.overrides.MuiToolbar.regular.height,
        height: '100%',
        background: 'linear-gradient(175deg, #000000 53%, #313131 100%)',
    },
    toolbar: {
        height: theme.overrides.MuiToolbar.regular.height,
    },
    navigation: {
        padding: '0',
        flexGrow: '1'
    },
    bottomNavigation: {
        padding: '0',
    },
    bottomnavigationItem: {
        background: theme.palette.primary.main,
        height: '88px',
        padding: '0',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.3)',
        }
    },
    navigationItemIcon: {
        color: '#ffffff',
        opacity: '0.3',
        width: theme.overrides.MuiToolbar.regular.height,
        display: 'flex',
        justifyContent: 'center',
    },
    navigationItemLine: {
        content: '',
        display: 'block',
        position: 'absolute',
        width: '4px',
        height: '100%',
        background: theme.palette.secondary.dark,
        opacity: '0',
        zoom: '0',
        transition: 'all .3s ease-in-out'
    },
    navigationItem: {
        height: '88px',
        padding: '0',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.06)',
        }
    },
    active: {
        background: 'rgba(255, 255, 255, 0.06)',
        '& div': {
            opacity: '1',
            zoom: '1'
        }
    },
    bottomActive: {
        background: 'rgba(0, 0, 0, 0.06)',
        '& div': {
            opacity: '1',
            zoom: '1'
        }
    },
    content: {
        padding: '10px 35px 30px 118px',
        backgroundColor: '#fafafa',
    },
    contentWrapper: {
        background: 'linear-gradient(175deg, #000000 53%, #313131 100%)',
    },
    contentLogo: {
        maxHeight: '80px',
        maxWidth: '160px',
        marginBottom: '-3px',
        display: 'inline-block',
    },
    progress: {
        flexGrow: 1
    }
});

let api_base = process.env.REACT_APP_API_URL;

class HomeScene extends Component {
    state = {
        clients: [],
        selectedClient: localStorage.getItem('selectedClient'),
        open: false,
        client: {},
        loading: false
    };

    componentDidMount() {

        fetch(`${api_base}/clients/`)
            .then(response => response.json())
            .then(data => {
                    this.setState({clients: data.body});
                    if (this.state.selectedClient === null) {
                        this.setState({selectedClient: data.body[0].id});
                        localStorage.setItem('selectedClient', data.body[0].id);
                    }
                }
            );

        fetch(`${api_base}/clients/${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                this.setState({client: data.body});
            });
    }

    onClientChange(id) {
        this.setState({selectedClient: id});
        localStorage.setItem('selectedClient', id);
        window.location.reload();
    }

    handleHoverOn = event => {
        this.setState({open: true, anchorEl: event.currentTarget});
    };

    handleHoverOut = () => {
        this.setState({open: false});
    };

    toggleLoading = (show) => {
        this.setState({loading: show})
    }

    render() {
        const {classes} = this.props;
        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline/>
                    {this.state.loading && <LinearProgress className={classes.progress}/>}
                    <AppBar position="relative" className={classes.appBar}>
                        <Toolbar className={classNames(classes.topBar)}>
                            <a className={classes.logo}
                               href="/"
                            >
                                <img alt='Resultify Insights' src='/static/img/new_r_w.svg' className={classes.riLogo}/>
                            </a>
                            <div className={classes.logo_text}>
                                {(this.state.client.logo === " ") || (this.state.client.logo === undefined) ?
                                    <b>{this.state.client.name} Insights</b>
                                    : <img className={classes.contentLogo} src={this.state.client.logo} alt='logo'/>
                                }
                            </div>
                            {/* <TopNav/> */}
                            <TopRightNav
                                clients={this.state.clients}
                                selectedClient={this.state.selectedClient}
                                onClientChange={(id) => this.onClientChange(id)}
                            />
                        </Toolbar>
                    </AppBar>
                    <div className={classes.contentWrapper}>
                        <Drawer
                            onMouseOver={this.handleHoverOn}
                            onMouseOut={this.handleHoverOut}
                            variant="permanent"
                            classes={{
                                paper: classNames(classes.drawer, {
                                    [classes.drawerPaperOpen]: this.state.open,
                                    [classes.drawerPaper]: !this.state.open,
                                }),
                            }}
                        >
                            <div className={classes.backgroundForIcons}/>
                            <List className={classes.navigation}>
                                <ListItem className={classes.navigationItem} button component={NavLink} to="/dashboard"
                                          color="primary" activeClassName={classes.active}>
                                    <div className={classes.navigationItemLine}></div>
                                    <ListItemIcon className={classes.navigationItemIcon}><GeneralIcon name='squares'
                                                                                                      width={33}
                                                                                                      height={23}
                                                                                                      fill={'#ffffff'}/></ListItemIcon>
                                    <ListItemText>Dashboards</ListItemText>
                                </ListItem>
                                <ListItem className={classes.navigationItem} button component={NavLink}
                                          to="/connections" color="primary" activeClassName={classes.active}>
                                    <div className={classes.navigationItemLine}></div>
                                    <ListItemIcon className={classes.navigationItemIcon}><GeneralIcon name='connection'
                                                                                                      width={33}
                                                                                                      height={26}
                                                                                                      fill={'#ffffff'}/></ListItemIcon>
                                    <ListItemText>Connections</ListItemText>
                                </ListItem>
                                <ListItem className={classes.bottomnavigationItem} button component={NavLink}
                                          to="/settings" color="primary" activeClassName={classes.bottomActive}>
                                    <div className={classes.navigationItemLine}></div>
                                    <ListItemIcon className={classes.navigationItemIcon}>
                                        <GeneralIcon name='settings' width={30} height={30} fill={'#ffffff'}/>
                                        {/* <SettingsOutlinedIcon className={classes.navigationItemIcon}></SettingsOutlinedIcon> */}
                                    </ListItemIcon>
                                    <ListItemText>Value / Goals</ListItemText>
                                </ListItem>
                            </List>
                            <List className={classes.bottomNavigation}>
                                <ListItem className={classes.navigationItem} button component={NavLink} to="/clients"
                                          color="primary" activeClassName={classes.active}>
                                    <div className={classes.navigationItemLine}></div>
                                    <ListItemIcon className={classes.navigationItemIcon}><GeneralIcon name='speed'
                                                                                                      width={33}
                                                                                                      height={20}
                                                                                                      fill={'#ffffff'}/></ListItemIcon>
                                    <ListItemText>Clients</ListItemText>
                                </ListItem>
                            </List>
                        </Drawer>
                        <div className={classNames(classes.content)}>
                            <Route path="/" exact component={DashboardList}/>
                            <Route path="/index.html" exact component={DashboardList}/>
                            <Route path="/dashboard" exact component={DashboardList}/>
                            <Route path="/dashboard/:dashboard" exact
                                   render={(props) => <Dashboard {...props} toggleLoading={this.toggleLoading}/>}/>
                            <Route path="/connections" exact component={Connections}/>
                            <Route path="/clients" exact component={Clients}/>
                            <Route path="/clients/:id" exact component={Client}/>
                            <Route path="/settings" exact component={Settings}/>
                            <Route path="/usersettings" exact component={UserSettings}/>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

HomeScene.propTypes = {};

export default withStyles(styles)(HomeScene);