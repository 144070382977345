import React, { Component } from 'react';

import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DatesPicker.css';
import isSameDay from 'react-dates/src/utils/isSameDay';
import { withStyles, withStylesPropTypes, css } from 'react-with-styles';
import { DateRangePicker } from 'react-dates';
//import {DateRangePicker} from 'react-dates/src/components/DateRangePicker';
import { DateRangePickerPhrases } from 'react-dates/src/defaultPhrases';
import DateRangePickerShape from 'react-dates/src/shapes/DateRangePickerShape';
import omit from 'lodash/omit';
import moment from 'moment';

let startDynamic = moment().subtract(1, 'week');
let endDynamic =  moment();
const START_DATE = 'startDate';
const END_DATE = 'endDate';
const today = moment();
const yesterday = moment().subtract(1, 'day');
const presets = [{
  text: 'Today',
  start: today,
  end: today,
},
{
  text: 'Yesterday',
  start: yesterday,
  end: yesterday,
}];

const propTypes= {
    ...withStylesPropTypes,
  
    // example props for the demo
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    presets: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      start: momentPropTypes.momentObj,
      end: momentPropTypes.momentObj,
    })),
  
    ...omit(DateRangePickerShape, [
      'startDate',
      'endDate',
      'onDatesChange',
      'focusedInput',
      'onFocusChange',
    ]),
};

const defaultProps = {
    // example props for the demo
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
    presets: [],
  
    // input related props
    startDateId: START_DATE,
    startDatePlaceholderText: 'Start Date',
    endDateId: END_DATE,
    endDatePlaceholderText: 'End Date',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    showClearDates: false,
    showDefaultInputIcon: false,
    customInputIcon: null,
    customArrowIcon: null,
    customCloseIcon: null,
  
    // calendar presentation and interaction related props
    renderMonthText: null,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,
  
    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},
  
    // day presentation and interaction related props
    renderDayContents: null,
    minimumNights: 0,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: day => false,
    isDayHighlighted: () => false,
  
    // internationalization
    displayFormat: () => moment.localeData().longDateFormat('L'),
    monthFormat: 'MMMM YYYY',
    phrases: DateRangePickerPhrases,
};

class DatesPicker extends Component {
    constructor(props) {
      super(props);
  
      let focusedInput = null;
      if (props.autoFocus) {
        focusedInput =  START_DATE;
      } else if (props.autoFocusEndDate) {
        focusedInput = END_DATE;
      }
  
      this.state = {
        focusedInput,
        startDate: startDynamic,
        endDate:  endDynamic,
        selectedRange: null,
      };
  
      this.onDatesChange = this.onDatesChange.bind(this);
      this.onFocusChange = this.onFocusChange.bind(this);
      this.renderDatePresets = this.renderDatePresets.bind(this);

      if (props.getCurrentPoint){
        props.getCurrentPoint(this.getMapPoint.bind(this));
      }
    }
  

    onDatesChange({ startDate, endDate, selectedRange}) {
        this.setState({ startDate, endDate });
        let startDateString = startDate.toISOString();
        if (endDate !== null){
            let endDateString = endDate.toISOString();
            if (selectedRange) {
                this.props.selectedDates(selectedRange, startDateString, endDateString);
                startDynamic = startDate;
                endDynamic = endDate;
            } else {
                let mixed = startDate.format('DD MMM YYYY')+' / '+ endDate.format('DD MMM YYYY');
               this.props.selectedDates(mixed, startDateString, endDateString);
               startDynamic = startDate;
               endDynamic = endDate;
            }
        }
      }
  
    onFocusChange(focusedInput) {
      this.setState({ focusedInput });
    }
  
    renderDatePresets() {
      //const { presets, styles } = this.props;
      const { styles } = this.props;
      const { startDate, endDate} = this.state;
  
      return (
        <div {...css(styles.PresetDateRangePicker_panel)}>
          {presets.map(item => {
            const isSelected = isSameDay(item.start, startDate) && isSameDay(item.end, endDate);
            return (
              <button
                key={item.text}
                {...css(
                  styles.PresetDateRangePicker_button,
                  isSelected && styles.PresetDateRangePicker_button__selected,
                )}
                type="button"
                onClick={() => this.onDatesChange({ startDate: item.start, endDate: item.end, selectedRange: item.text})}
              >
                {item.text}
              </button>
            );
          })}
        </div>
      );
    }
  
    render() {
      const { focusedInput, startDate, endDate } = this.state;
  
      // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
      // example wrapper but are not props on the SingleDatePicker itself and
      // thus, have to be omitted.
      const props = omit(this.props, [
        'autoFocus',
        'autoFocusEndDate',
        'initialStartDate',
        'initialEndDate',
        'presets',
      ]);

  
      return (
        <div>
          <DateRangePicker
            {...props}
            renderCalendarInfo={this.renderDatePresets}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            small={true}
            displayFormat="DD MMM YYYY"
            isOutsideRange= {day => {
                let isBlocked = false;
                if ((moment().subtract(6, 'month').diff(day)) > 0) {
                    // block earlier dates than 6 month 
                    isBlocked = true;
                   } else if (moment().diff(day) < 0){
                    //block future dates
                    isBlocked = true;
                   }
                return isBlocked;  // return true, if the current day is blocked;
             }
             }
          />
        </div>
      );
    }
}

//export default  withStyles(styles)(DatesPicker);

DatesPicker.propTypes = propTypes;
DatesPicker.defaultProps = defaultProps;

export default withStyles(({ reactDates: { color } }) => ({
    PresetDateRangePicker_panel: {
      padding: "0 22px 11px 22px"
    },
  
    PresetDateRangePicker_button: {
      position: "relative",
      height: "100%",
      textAlign: "center",
      background: "none",
      border: `7px solid #000`,
      color: 'red',
      padding: "4px 12px",
      marginRight: 8,
      font: "inherit",
      fontWeight: 700,
      lineHeight: "normal",
      overflow: "visible",
      boxSizing: "border-box",
      cursor: "pointer",
  
      ":active": {
        outline: 0
      }
    },
  
    PresetDateRangePicker_button__selected: {
      color: color.core.white,
      background: 'red'
    }
  }))(DatesPicker);
