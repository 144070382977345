import  {createMuiTheme}  from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            light: '#fffb4e',
            main: '#1f1f1f',
            dark: '#f7b22c',
            contrastText: '#ffffff',
            active: '#2e2e2e'
        },
        secondary: {
            main: '#ffde28',
            dark: '#fffb4e',
            contrastText: '#000000',
        },
        lightDark:{
            main: '#333333',
            active: '#404040'
        }
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#fffb4e',
                color: '#000'
            },
        },
        MuiToolbar: {
            regular: {
                height: '82px',
                padding:'0',
            }  
        },
        MuiIconButton: {
            colorPrimary: {
                color: '#fff' 
                
            }
        },
        MuiSelect:{
            root:{
                color:'#000'
            }
        },
        MuiListItemText: {
            primary: {
                color: '#fff',
                textTransform: 'uppercase',
                'font-family': 'IBM Plex Sans, sans-serif',
            }
        }

    },
    typography: {
        fontFamily:
            '-apple-system,system-ui,BlinkMacSystemFont,' +
            'Lato, Roboto,"Helvetica Neue",Arial,sans-serif',
        useNextVariants: true
    }
});