import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// DashboardContainer Card components
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

//styles:
import './../../styles/dashboard.css';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
//import MenuItem from "@material-ui/core/MenuItem";
import Select from 'react-select';
import DefaultDrawer from "../../components/Drawer";
import AddButton from "../../components/AddButton";

const customSelectStyles = {
    control: () => ({
        display: "flex",
        alignItems: "center",
        border: 0,
        height: "auto",
        background: "transparent",
        position: "relative",
        "&:hover": {
            boxShadow: "none",
        },
        "&:after": {
            content: "''",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:after": {
            borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
        },
    }),
    menu: () => ({
        backgroundColor: "white",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        position: "absolute",
        left: 0,
        top: "calc(100% + 1px)",
        width: "100%",
        zIndex: 200,
        maxHeight: 200
    }),
    valueContainer: () => ({
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "6px 0 7px",
    }),
    menuList: () => ({
        maxHeight: 200,
        overflowY: "auto",
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    singleValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    multiValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
        backgroundColor: "hsl(0,0%,90%)",
        boxSizing: "border-box",
        display: "flex",
        borderRadius: "2px",
    })
};

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerContainer: {
        padding: 20
    },
    formGroup: {
        padding: 10
    },
    formControl: {
        width: '100%'
    },
    actions: {
        justifyContent: 'space-evenly'
    },
});

const dashboardTypes = [
    {'id': 'google_analytics', 'name': 'Google Analytics default'},
    {'id': 'facebook', 'name': 'Facebook default'},
    {'id': 'value', 'name': 'Value'},
    {'id': 'summary', 'name': 'Summary'}
];

let api_base = process.env.REACT_APP_API_URL;

class DashboardForm extends Component {

    findInArrayById(id, array) {
        let result = array.find(item => item.id === id);

        return result !== undefined ? result : {};
    }

    render() {
        let classes = this.props.classes;
        let initialValues = {
            'name': '',
            'type': 'google_analytics',
            'connection': []
        };
        if (this.props.dashboard !== undefined) {
            Object.keys(this.props.dashboard).map(key => initialValues[key] = this.props.dashboard[key]);
        }
        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        this.props.handleSubmit(JSON.stringify(values, null, 2), initialValues.Dashboard);
                        actions.setSubmitting(false);
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Name</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        name="name"
                                    />
                                </FormGroup>



                                {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                <FormGroup className={classes.fromGroup}>
                                    <Button disabled={props.isSubmitting ? true : false} type="submit"
                                            variant="contained"
                                            color="primary">{this.props.dashboard['Client'] === undefined ? 'Submit' : 'Update'}</Button>
                                </FormGroup>
                            </FormControl>
                        </form>
                    )}
                />
            </div>
        )
    }
}


class DashboardList extends Component {
    state = {
        showAlertDialog: false,
        dialogId: '',
        dashboards: [],
        connections: [],
        selectedDashboard: {},
        sideOpen: false
    };

    componentDidMount() {
        fetch(`${api_base}/dashboards/?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => this.setState({dashboards: data.body}));
        fetch(`${api_base}/connections/?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => this.setState({connections: data.body}));
    }

    toggleDrawer = (open, dashboard) => () => {
        this.setState({
            selectedDashboard: dashboard,
            sideOpen: open
        });
    };

    handleAlertDialog = (id) => {
        this.setState({
            showAlertDialog: !this.state.showAlertDialog,
            dialogId: id
        })
    }

    setDashboardId = (id) => {
        let dashboard = this.state.dashboards.find((item) => item.Dashboard === id);
        this.setState({
            selectedDashboard: dashboard,
            sideOpen: true
        });
    };

    removeDashboardById = () => {
        fetch(`${api_base}/dashboards/${this.state.dialogId}?client=${localStorage.selectedClient}`, {
            method: 'DELETE',
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/dashboards/?client=${localStorage.selectedClient}`)
                    .then(response => response.json())
                    .then(data => this.setState({
                        dashboards: data.body,
                        showAlertDialog: false
                    }));
            }
        )
    };

    updateDashboard = (data, id) => {
        fetch(id === undefined ? `${api_base}/dashboards/?client=${localStorage.selectedClient}` : `${api_base}/dashboards/${id}?client=${localStorage.selectedClient}`, {
            method: id === undefined ? 'POST' : 'PUT',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/dashboards/?client=${localStorage.selectedClient}`)
                    .then(response => response.json())
                    .then(data => this.setState({dashboards: data.body, sideOpen: false}));
            }
        )
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={16} style={{
                    margin: 0,
                    width: '100%',
                }}>
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5">
                                My Dashboards
                            </Typography>
                            <div style={{flexGrow: 1, textAlign: 'right'}}>
                                <AddButton
                                    name='Add dashboard'
                                    onClick={this.toggleDrawer(true, {})}
                                />
                            </div>
                        </div>
                    </Grid>
                    {this.state.dashboards.map((dashboard) =>
                        <Grid item xs={6} sm={4} key={dashboard.Dashboard}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to={`/dashboard/${dashboard.Dashboard}`}>
                                    <div style={{height: '140px', backgroundColor: '#000000'}}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            className={classes.media}
                                            image="/static/img/new_r_w.svg"
                                        />
                                    </div>
                                    <CardContent>
                                        <Typography variant="h6" component="h2">
                                            {dashboard.name}
                                        </Typography>
                                        <Typography component="p">

                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions className={classes.actions} disableActionSpacing>
                                    <IconButton aria-label="Edit"
                                                onClick={this.setDashboardId.bind(this, dashboard.Dashboard)}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton aria-label="Remove"
                                                onClick={this.handleAlertDialog.bind(this, dashboard.Dashboard)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                {/*Right sidebar*/}
                <DefaultDrawer
                    open={this.state.sideOpen}
                    name={this.state.selectedDashboard['Client'] === undefined ? 'Add dashboard' : 'Edit dashboard'}
                    onClose={this.toggleDrawer(false, {})}
                    content={
                        <div className={classes.drawerContainer}>
                            <DashboardForm classes={this.props.classes} dashboard={this.state.selectedDashboard}
                                           handleSubmit={this.updateDashboard} connections={this.state.connections}/>
                        </div>
                    }
                />

                {/*Dialog Component*/}
                <Dialog
                    open={this.state.showAlertDialog}
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm deleting a company"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            When Deleting a dashboard, all the data will be lost. <br/>
                            Are you sure you want to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertDialog} color="primary" autoFocus>
                            Discard
                        </Button>
                        <Button onClick={this.removeDashboardById} color="primary">
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DashboardList);