import React, {Component} from 'react';

let api_base = process.env.REACT_APP_API_URL;


class DropInput extends Component {
    onClickHandler = event => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${api_base}/profile/upload`, {
            method: 'POST',
            body: data,
        }).then(response => response.json())
            .then(data => {
                this.props.onChange(data.body.file);
            });
    };

    render() {
        return (
            <div style={{padding: "10px 0"}}>
                <input type="file" onChange={this.onClickHandler}/>
            </div>
        );
    }
}

export default DropInput;