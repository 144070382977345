import React from "react";
import { SignIn } from "aws-amplify-react";
import GeneralIcon from "../../../Icons/GeneralIcons";

//styles
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import '../index.css';

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];

    this.state = {
      password: '',
      showPassword: false,
    };
  }
  
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
    this.handleInputChange(event);
  };

  showComponent(theme) {
    return (
      
      <Grid GenerallIcon container spacing={0} style={{margin: 0, width: '100%',}}  className="signInContainer">
      <Grid item xs={false} sm={1} md={2}></Grid>
      <Grid item xs={12} sm={6} md={5} lg={4}>
      <Paper xs={12} className="signInWrapper">
        <div className="signInHeader">
          <GeneralIcon className="signInHeaderIcon" name='account' width={100} fill={'#535353'}/>
          <Typography variant="h5" className="bold">
              Sign In
          </Typography>
          <Typography variant="body1" className="bold">
               Sign in to your account
          </Typography>
        </div>
          <Grid className="signInForm" >
            <Typography variant="body1" 
              className="bold"
            >
              Username
            </Typography>
            <Input
              className="signInInput"
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              type="text"
              placeholder="Username"
            />
            <Typography variant="body1" 
              className="bold"
            >
              Password
            </Typography>
            <Input
              className="signInInput"
              id="password"
              key="password"
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              onChange={this.handleChange('password')}
              placeholder="Ender your password"
              endAdornment={
              <InputAdornment position="end">
                <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}>
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
              }
            />
          </Grid>
          <Grid 
            container
            direction="row" 
            justify="flex-end">
            <Button variant="outlined" 
                onClick={(event) => super.signIn(event)}
                className="signInBtn"
            >
                Sign In 
            </Button>
          </Grid>
          <Grid
            className="signInLinks"
            container
            justify="flex-start"
            alignItems="flex-end"
          >
            <Typography variant="body1" className="small">
                Forgot your password?{" "}
                <Button 
                  className="BtnAsLink"
                  onClick={() => super.changeState("forgotPassword")}
                >
                  Reset Password
                </Button>
            </Typography>
          </Grid>
      </Paper> 
      </Grid> 
      </Grid>

    );
  }
};
