import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
    editPenIcon: {
        float: 'right',
        right: 0,
        position: 'relative',
        top: 0,
    }
});



class NumericWidget extends React.Component {
    state = {
    };

    render() {
        const {classes} = this.props;
        const dashboard = this.props.dashboard;
        const onClose = this.props.onClose;
        const widget = this.props.widget;
        const value = this.props.value;
        return (
            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                   elevation={1}>
                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                    <EditIcon onClick={this.props.handleEditWidget.bind(this, widget, this.props.index)}/>
                </IconButton>
                <div className="widget-hd">
                    {widget.name}
                </div>
                <div className="widget-body">
                    <div className="widget-value">
                       {value} {widget.units}
                    </div>
                    <div className="widget-delta">
                        {widget.description}
                    </div>
                </div>
            </Paper>
        );
    }
}

NumericWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumericWidget);
