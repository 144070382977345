import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.css';
import AppWithAuth from "./AppWithAuth";
import ShareScene from "./scenes/Share";
import {MuiThemeProvider} from '@material-ui/core/styles';
import theme from './theme';


class AppRoute extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <Switch>
                        <Route path="/share/" component={ShareScene}/>
                        <Route exact component={AppWithAuth}/>
                    </Switch>
                </Router>
            </MuiThemeProvider>
        )
    }
}

export default AppRoute;