import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Table from '../../../components/Table';

//Styles:
import '../../../styles/dashboard.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AppsIcon from '@material-ui/icons/Apps';
import DehazeIcon from '@material-ui/icons/Dehaze';
import AddIcon from '@material-ui/icons/Add';
import SvgIcon from "@material-ui/core/SvgIcon";
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';

const customSelectStyles = {
    control: () => ({
        display: "flex",
        alignItems: "center",
        border: 0,
        height: "auto",
        background: "transparent",
        position: "relative",
        "&:hover": {
            boxShadow: "none",
        },
        "&:after": {
            content: "''",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:after": {
            borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
        },
    }),
    menu: () => ({
        backgroundColor: "white",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        position: "absolute",
        left: 0,
        top: "calc(100% + 1px)",
        width: "100%",
        zIndex: 200,
        maxHeight: 200
    }),
    valueContainer: () => ({
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "6px 0 7px",
    }),
    menuList: () => ({
        maxHeight: 200,
        overflowY: "auto",
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    singleValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    multiValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
        backgroundColor: "hsl(0,0%,90%)",
        boxSizing: "border-box",
        display: "flex",
        borderRadius: "2px",
    })
};


const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        paddingLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerContainer: {
        padding: 20
    },
    formGroup: {
        padding: 10
    },
    facebookAvatar: {
        background: '#3C5A99'
    },
    google_analyticsAvatar: {
        background: '#FFC517'
    },
    actions: {
        justifyContent: 'space-evenly'
    },
    formControl: {
        width: '100%'
    },
});

const socialIcon = (network) => {
    if (network === 'facebook') return <SvgIcon>
        <path
            d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2		C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"></path>
    </SvgIcon>
    if (network === 'google_analytics') return <SvgIcon width={'20px'} height={'20px'} fill={'#ffffff'} x={0} y={0}
                                                        viewBox={"0 0 101.968 101.968"}>
        <g>
            <path d="M24.715,47.432L7.968,64.86v29.406c0,0.828,0.671,1.5,1.5,1.5h20.334c0.828,0,1.5-0.672,1.5-1.5V49.158l-4.69-1.726
			H24.715z"/>
            <path d="M66.135,61.1H45.801c-0.828,0-1.5,0.672-1.5,1.5v31.666c0,0.828,0.672,1.5,1.5,1.5h20.334c0.829,0,1.5-0.672,1.5-1.5V62.6
			C67.635,61.772,66.964,61.1,66.135,61.1z"/>
            <path d="M101.724,29.49c-0.777,0.406-1.652,0.621-2.53,0.621c-1.276,0-2.521-0.45-3.5-1.27l-3.694-3.088l-13.365,14.58v53.934
			c0,0.828,0.672,1.5,1.5,1.5h20.334c0.829,0,1.5-0.672,1.5-1.5v-64.93C101.885,29.387,101.81,29.445,101.724,29.49z"/>
            <path d="M57.797,54.094c1.144,0.419,2.424,0.108,3.248-0.788l30.839-33.643l7.217,6.032c0.353,0.294,0.847,0.349,1.254,0.136
			c0.407-0.214,0.646-0.648,0.605-1.107L99.396,7.235c-0.055-0.625-0.606-1.086-1.231-1.029l-17.49,1.563
			c-0.458,0.041-0.846,0.354-0.982,0.791C79.646,8.706,79.631,8.854,79.644,9c0.026,0.294,0.167,0.572,0.403,0.769l7.229,6.043
			L57.98,47.769L24.535,35.463c-1.118-0.41-2.373-0.121-3.198,0.735l-20.5,21.333c-1.148,1.195-1.11,3.095,0.084,4.242
			c0.583,0.561,1.332,0.837,2.079,0.837c0.788,0,1.574-0.309,2.164-0.921l19.141-19.92L57.797,54.094z"/>
        </g>
    </SvgIcon>
};


let api_base = process.env.REACT_APP_API_URL;

class ValueForm extends Component {
    state = {
        columns: {
            name: {title: 'Name', field: 'name'},
            network: {
                title: 'Network',
                field: 'network',
                lookup: {
                    'facebook': 'Facebook',
                    'google_analytics': 'Google Analytics',
                }
            },
            trigger: {
                title: 'Trigger', field: 'trigger',
                lookupFB: {
                    'page_impressions_unique': 'Page Reach (FB)',
                    'page_fan_adds_unique': 'Page likes (FB)',
                    'page_video_views': 'Video views (FB)',
                },
                lookupGA: {
                    'ga:pageviews': 'Pageviews (GA)',
                    'ga:sessions': 'Sessions (GA)',
                    'ga:bounces': 'Bounces (GA)',
                    'ga:totalEvents': 'Total Events (GA)',
                    'ga:uniqueEvents': 'Event (GA)'
                }
            },
            value: {title: 'Value', field: 'value', type: 'numeric'},
            event: {
                title: 'Event',
                field: 'event'
            },
            event_action: {
                title: 'Event action',
                field: 'event_action'
            },
            event_label: {
                title: 'Event label',
                field: 'event_label'
            },
            funnel: {
                title: 'Funnel',
                field: 'funnel',
                lookup:
                    {
                        'exposure': 'Exposure',
                        'engage': 'Engage',
                        'inspire': 'Inspire',
                        'convert': 'Convert',
                        'recommend': 'Recommend'
                    }
            }
        }
    };

    updateOptions(args) {

    }

    submitForm(values) {
        if (Object.keys(this.props.goal).length > 0) {
            this.props.onUpdate(values, this.props.goal);
        } else {
            this.props.onAdd(values);
        }
    }

    findInArrayById(id, array) {
        let result = array.find(item => item.id === id);

        return result !== undefined ? result : {};
    }

    render() {
        const {classes} = this.props;
        let initialValues = {
            'name': ' ',
            'network': 'google_analytics',
            'trigger': ' ',
            'value': 0,
            'funnel': ' ',
            'event': ' ',
            'event_action': ' ',
            'event_label': ' '
        };

        if (Object.keys(this.props.goal).length > 0) {
            Object.keys(this.props.goal).map(key => initialValues[key] = this.props.goal[key]);
        }

        return (
            <div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, actions, el) => {
                        this.submitForm(values);
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <input type="hidden" name='Client' value={localStorage.selectedClient}/>

                                <FormControl component="fieldset" className={classes.formControl}>
                                    <input type="hidden" name='Client' value={localStorage.selectedClient}/>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Name</FormLabel>
                                        <TextField
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                            name="name"
                                        />
                                    </FormGroup>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Network</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.network,
                                                'label': this.state.columns.network.lookup[props.values.network]
                                            }}
                                            onChange={e => {
                                                this.updateOptions({
                                                    'network': e.value,
                                                    'field': 'network'
                                                });
                                                props.setFieldValue('network', e.value);
                                            }}
                                            name='network'
                                            options={Object.keys(this.state.columns.network.lookup).map((key) => {
                                                return {'value': key, 'label': this.state.columns.network.lookup[key]}
                                            })}
                                        />
                                    </FormGroup>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Trigger</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.trigger,
                                                'label': this.state.columns.trigger[props.values.network === 'facebook' ? 'lookupFB' : 'lookupGA'][props.values.trigger]
                                            }}
                                            onChange={e => {
                                                this.updateOptions({
                                                    'trigger': e.value,
                                                    'field': 'trigger'
                                                });
                                                props.setFieldValue('trigger', e.value);
                                            }}
                                            name='trigger'
                                            options={Object.keys(this.state.columns.trigger[props.values.network === 'facebook' ? 'lookupFB' : 'lookupGA']).map((key) => {
                                                return {
                                                    'value': key,
                                                    'label': this.state.columns.trigger[props.values.network === 'facebook' ? 'lookupFB' : 'lookupGA'][key]
                                                }
                                            })}
                                        />
                                    </FormGroup>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Value</FormLabel>
                                        <TextField
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.value}
                                            name="value"
                                        />
                                    </FormGroup>

                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Funnel</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.funnel,
                                                'label': this.state.columns.funnel.lookup[props.values.funnel]
                                            }}
                                            onChange={e => {
                                                this.updateOptions({
                                                    'funnel': e.value,
                                                    'field': 'funnel'
                                                });
                                                props.setFieldValue('funnel', e.value);
                                            }}
                                            name='funnel'
                                            options={Object.keys(this.state.columns.funnel.lookup).map((key) => {
                                                return {
                                                    'value': key,
                                                    'label': this.state.columns.funnel.lookup[key]
                                                }
                                            })}
                                        />
                                    </FormGroup>
                                    {((props.values.trigger === 'ga:totalEvents') || (props.values.trigger === 'ga:uniqueEvents')) &&
                                    <div>
                                        <FormGroup className={classes.formGroup}>
                                            <FormLabel component="legend">Event</FormLabel>
                                            <Select
                                                styles={customSelectStyles}
                                                value={{
                                                    'value': props.values.event,
                                                    'label': this.props.eventCategories[props.values.event]
                                                }}
                                                onChange={e => {
                                                    this.updateOptions({
                                                        'event': e.value,
                                                        'field': 'event'
                                                    });
                                                    props.setFieldValue('event', e.value);
                                                }}
                                                name='event'
                                                options={Object.keys(this.props.eventCategories).map((key) => {
                                                    return {
                                                        'value': key,
                                                        'label': this.props.eventCategories[key]
                                                    }
                                                })}
                                            />
                                        </FormGroup>
                                        <FormGroup className={classes.formGroup}>
                                            <FormLabel component="legend">Event Action</FormLabel>
                                            <Select
                                                styles={customSelectStyles}
                                                value={{
                                                    'value': props.values.event_action,
                                                    'label': this.props.eventActions[props.values.event_action]
                                                }}
                                                onChange={e => {
                                                    this.updateOptions({
                                                        'event_action': e.value,
                                                        'field': 'event_action'
                                                    });
                                                    props.setFieldValue('event_action', e.value);
                                                }}
                                                name='event_action'
                                                options={Object.keys(this.props.eventActions).map((key) => {
                                                    return {
                                                        'value': key,
                                                        'label': this.props.eventActions[key]
                                                    }
                                                })}
                                            />
                                        </FormGroup>
                                        <FormGroup className={classes.formGroup}>
                                            <FormLabel component="legend">Event Label</FormLabel>
                                            <Select
                                                styles={customSelectStyles}
                                                value={{
                                                    'value': props.values.event_label,
                                                    'label': this.props.eventLabels[props.values.event_label]
                                                }}
                                                onChange={e => {
                                                    this.updateOptions({
                                                        'event_label': e.value,
                                                        'field': 'event_label'
                                                    });
                                                    props.setFieldValue('event_label', e.value);
                                                }}
                                                name='event_label'
                                                options={Object.keys(this.props.eventLabels).map((key) => {
                                                    return {
                                                        'value': key,
                                                        'label': this.props.eventLabels[key]
                                                    }
                                                })}
                                            />
                                        </FormGroup>
                                    </div>
                                    }
                                    <FormGroup className={classes.formGroup}>
                                        {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                        <Button type="submit" variant="contained"
                                                color="primary">
                                            {Object.keys(this.props.goal).length > 0 ? 'Save' : 'Add'}
                                        </Button>
                                    </FormGroup>
                                </FormControl>

                        </form>
                    )}
                />
            </div>
        )
    }
}

class Values extends Component {
    state = {
        showAlertDialog: false,
        sideOpen: false,
        columns: [
            {title: 'Name', field: 'name'},
            {
                title: 'Network',
                field: 'network',
                lookup: {
                    'facebook': 'Facebook',
                    'google_analytics': 'Google Analytics',
                }
            },
            {
                title: 'Trigger', field: 'trigger',
                lookup: {
                    'page_impressions_unique': 'Page Reach',
                    'page_fan_adds_unique': 'Page likes',
                    'page_video_views': 'Video views',
                    'ga:pageviews': 'Pageviews',
                    'ga:sessions': 'Sessions',
                    'ga:bounces': 'Bounces',
                    'ga:totalEvents': 'Total Events',
                    'ga:uniqueEvents': 'Event'
                }
            },
            {title: 'Value', field: 'value', type: 'numeric'},
            {
                title: 'Event',
                field: 'event'
            },
            {
                title: 'Event action',
                field: 'event_action'
            },
            {
                title: 'Event label',
                field: 'event_label'
            },
            {
                title: 'Funnel',
                field: 'funnel',
                lookup:
                    {
                        'exposure': 'Exposure',
                        'engage': 'Engage',
                        'inspire': 'Inspire',
                        'convert': 'Convert',
                        'recommend': 'Recommend'
                    }
            }
        ],
        eventCategories: {},
        eventActions: {},
        eventLabels: {},
        data: [],
        currentGoal: {},
        valueView: 'cards'
    };


    componentDidMount() {
        fetch(`${api_base}/settings/values?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                    this.setState({data: data.body});
                }
            );
        fetch(`${api_base}/connections/get_events_options?field=event&client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                    let columns = this.state.columns;
                    let column = columns.find(item => item.field === 'event');
                    let index = columns.indexOf(column);
                    let lookup = {'-': '-'};
                    data.body.forEach(item => lookup[item.key] = item.name);
                    column['lookup'] = lookup;
                    columns[index] = column;

                    this.setState({columns: columns, eventCategories: column['lookup']});
                }
            );
        fetch(`${api_base}/connections/get_events_options?field=event_action&client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                    let columns = this.state.columns;
                    let column = columns.find(item => item.field === 'event_action');
                    let index = columns.indexOf(column);
                    let lookup = {'-': '-'};
                    data.body.forEach(item => lookup[item.key] = item.name);
                    column['lookup'] = lookup;
                    columns[index] = column;

                    this.setState({columns: columns, eventActions: column['lookup']});
                }
            );
        fetch(`${api_base}/connections/get_events_options?field=event_label&client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                    let columns = this.state.columns;
                    let column = columns.find(item => item.field === 'event_label');
                    let index = columns.indexOf(column);
                    let lookup = {'-': '-'};
                    data.body.forEach(item => lookup[item.key] = item.name);
                    column['lookup'] = lookup;
                    columns[index] = column;

                    this.setState({columns: columns, eventLabels: column['lookup']});
                }
            );
    }

    onAdd(row) {
        let data = this.state.data;
        row['key'] = '' + (data.length + 1);
        data.push(row);
        this.setState({data: data, valueView: this.state.valueView === 'cards' ? 'cards' : 'table'});
        this.props.onDrawerSubmit();
        fetch(`${api_base}/settings/values?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
    }

    onUpdate(row, oldrow) {
        let data = this.state.data;
        data[data.indexOf(oldrow)] = row;
        this.props.onDrawerSubmit();
        this.setState({data: data, valueView: this.state.valueView === 'cards' ? 'cards' : 'table'});
        fetch(`${api_base}/settings/values?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
    }

    onDelete(row) {
        let data = this.state.data;
        data.splice(data.indexOf(row), 1);
        this.setState({data: data});
        fetch(`${api_base}/settings/values?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
    }

    setValueView(view) {
        this.setState({valueView: view});
    }

    setCurrentGoal(goal) {
        this.setState({currentGoal: goal});
        this.props.setDrawerContent(
            <ValueForm goal={goal}
                       classes={this.props.classes} eventCategories={this.state.eventCategories}
                       eventActions={this.state.eventActions} eventLabels={this.state.eventLabels}
                       onAdd={this.onAdd.bind(this)}
                       onUpdate={this.onUpdate.bind(this)}
            />
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6">
                                Values
                            </Typography>
                            <IconButton onClick={this.setValueView.bind(this, 'cards')}>
                                <AppsIcon/>
                            </IconButton>
                            <IconButton onClick={this.setValueView.bind(this, 'table')}>
                                <DehazeIcon/>
                            </IconButton>
                            <IconButton onClick={this.setCurrentGoal.bind(this, {})}>
                                <AddIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                    {this.state.valueView === 'cards' &&
                    this.state.data.map(item => {
                        return (
                            <Grid item md={this.props.full ? 12 : 4} sm={this.props.full ? 12 : 6} xs={12} key={item.id}>
                                <Card className={styles.paper} style={{padding: '10px'}}>
                                    <CardContent onClick={this.setCurrentGoal.bind(this, item)}>
                                        <div style={{display: 'flex'}}>
                                            <div style={{paddingRight: '10px'}}>
                                                <Avatar className={classes[item.network + 'Avatar']}>
                                                    {socialIcon(item.network)}
                                                </Avatar>
                                            </div>
                                            <div style={{flex: 1}}>
                                                <Typography variant="h5" component="h2">
                                                    {item.name} {item.value && ` - ${item.value} SEK`}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {item.trigger && <div>
                                                        {this.state.columns.find(item => item.field === 'trigger')['lookup'][item.trigger]}
                                                    </div>}
                                                    {item.event && <div>{item.event}</div>}
                                                    {item.eventAction && <div>{item.eventAction}</div>}
                                                    {item.eventLabel && <div>{item.eventLabel}</div>}
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <CardActions className={classes.actions} disableActionSpacing>
                                        <IconButton onClick={this.setCurrentGoal.bind(this, item)}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton onClick={this.onDelete.bind(this, item)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })
                    }
                    {this.state.valueView === 'table' &&
                    <Table
                        columns={this.state.columns}
                        data={this.state.data}
                        onAdd={this.onAdd.bind(this)}
                        onUpdate={this.onUpdate.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        grouping={true}
                    />
                    }
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Values);