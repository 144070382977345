import React, {Component} from 'react';
import Connections from '../../Connections';

class ShareConnectionScene extends Component {
    state = {
        types: []
    };


    render() {
        return (
            <div>
                <Connections share={true} shareCompany={this.props.match.params.cmp}/>
            </div>
        );
    }
}

export default ShareConnectionScene;