import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {withStyles} from '@material-ui/core/styles';
import CompanyCard from './../../components/CompanyCard';
import UserCard from './../../components/UserCard';
import {Formik} from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import DefaultDrawer from "../../components/Drawer";
import DropInput from "../../components/Forms/DropInput";
import AddButton from "../../components/AddButton";

//Styles:
import './../../styles/dashboard.css';

let api_base = process.env.REACT_APP_API_URL;

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        paddingLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerContainer: {
        padding: 20
    },
    formGroup: {
        padding: 10
    },
    formControl: {
        width: '100%'
    },
    formLogo: {
        maxWidth: 150,
        marginTop: 10,
    }
});

class ClientForm extends Component {
    render() {
        let classes = this.props.classes;
        let initialValues = {
            'name': '',
            'description': '',
            'email': '',
            'logo': ''
        };
        if (this.props.client !== undefined) {
            Object.keys(this.props.client).map(key => initialValues[key] = this.props.client[key]);
        }
        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        this.props.handleSubmit(JSON.stringify(values, null, 2), initialValues.id);
                        actions.setSubmitting(false);
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Name</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        name="name"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Description</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.description}
                                        name="description"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Contact email</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                        name="email"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Logo</FormLabel>
                                    <input type='hidden' name='logo' value={props.values.logo} />
                                    <DropInput onChange={value => {
                                        props.setFieldValue('logo', value);
                                    }}/>
                                    <img className={classes.formLogo} src={props.values.logo} alt='logo'/>
                                </FormGroup>
                                {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                <FormGroup className={classes.fromGroup}>
                                    <Button disabled={props.isSubmitting ? true : false} type="submit" variant="contained" color="primary">{this.props.client['id'] === undefined ? 'Submit' : 'Update'}</Button>
                                </FormGroup>
                            </FormControl>
                        </form>
                    )}
                />
            </div>
        )
    }
}

class Clients extends Component {
    state = {
        showAlertDialog: false,
        dialogId: '',
        currentView: '',
        client: '',
        clients: [],
        users: [],
        listUsers: false,
        sideOpen: false,
        selectedClient: {}
    };

    handleAlertDialog = (id) => {
        this.setState({
            showAlertDialog: !this.state.showAlertDialog,
            dialogId: id
        })
    };

    setClientId = (id) => {
        let client = this.state.clients.find((item) => item.id === id);
        this.setState({
            selectedClient: client,
            sideOpen: true
        });
    };

    removeClientById = () => {
        fetch(`${api_base}/clients/${this.state.dialogId}`, {
            method: 'DELETE',
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/clients/`)
                    .then(response => response.json())
                    .then(data => this.setState({
                        clients: data.body,
                        showAlertDialog: false
                    }));
            }
        )
    };

    componentDidMount() {
        fetch(`${api_base}/clients/`)
            .then(response => response.json())
            .then(data => this.setState({clients: data.body}));
    }

    updateClient = (data, id) => {
        fetch( id === undefined ? `${api_base}/clients/` :`${api_base}/clients/${id}`, {
            method: id === undefined ? 'POST' : 'PUT',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/clients/`)
                    .then(response => response.json())
                    .then(data => this.setState({
                        clients: data.body,
                        sideOpen: false
                    }));
            }
        )
    };

    toggleDrawer = (open, client) => () => {
        this.setState({
            selectedClient: client,
            sideOpen: open
        });
    };

    render() {
        const {classes} = this.props;
        //let clients = this.state.clientId && this.state.listUsers ? this.state.clients : CLIENTS;
        let clients = this.state.clients;
        return (
            <React.Fragment>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    {/*Header Component*/}
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5">
                                Clients
                            </Typography>
                            <div style={{flexGrow: 1, textAlign: 'right'}}>
                                <AddButton
                                    name='Add client'
                                    onClick={this.toggleDrawer(true, {})}
                                />
                            </div>
                        </div>
                    </Grid>
                    {/*Companies List*/}
                    {clients.map((client, key) => {
                        return (
                            <Grid item md={4} sm={6} xs={12} key={key}>
                                <CompanyCard key={client.id} client={client}
                                             showDialog={this.handleAlertDialog.bind(this, client.id)}
                                             setClientId={this.setClientId}/>
                            </Grid>
                        )
                    })}
                </Grid>
                {/*Users (not used anymore) */}
                {this.state.listUsers && (
                    <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                        <Grid item xs={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h5" style={{flexGrow: 1}}>
                                    Users ({this.state.users.length})
                                </Typography>
                                <div style={{textAlign: 'right'}}>
                                    <Paper className={styles.root} elevation={1}>
                                        <InputBase className={styles.input} placeholder="Filter Users"/>
                                        <IconButton className={styles.iconButton} aria-label="Search">
                                            <SearchIcon/>
                                        </IconButton>
                                    </Paper>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.users.map((user) => {
                                return (
                                    <UserCard user={user}/>
                                )
                            })}
                        </Grid>
                    </Grid>
                )}
                {/*Right sidebar*/}
                <DefaultDrawer
                    open={this.state.sideOpen}
                    name={this.state.selectedClient['id'] === undefined ? 'Add client' : 'Edit client'}
                    onClose={this.toggleDrawer(false, {})}
                    content= {
                        <div className={classes.drawerContainer}>
                            <ClientForm classes={this.props.classes} client={this.state.selectedClient} handleSubmit={this.updateClient}/>
                        </div>
                    }
                />
                {/*Dialog Component*/}
                <Dialog
                    open={this.state.showAlertDialog}
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm deleting a company"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            When Deleting a company, all the data will be lost.<br/>
                            All children companies and company users will be deleted as well. Are you sure you want to
                            proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertDialog} color="primary" autoFocus>
                            Discard
                        </Button>
                        <Button onClick={this.removeClientById} color="primary">
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Clients);