import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";

const styles = theme => ({
    editPenIcon: {
        float: 'right',
        right: 0,
        position: 'relative',
        top: 0,
    }
});

const COLORS = [
    '#ffde28', '#000000', '#ce3ac3',
    '#ffde28', '#000000', '#ce3ac3',
    '#ffde28', '#000000', '#ce3ac3',
    '#ffde28', '#000000', '#ce3ac3'
];

class LineChartWidget extends React.Component {
    state = {};

    getLineChartData(value) {
        let data = {};

        Object.keys(value).forEach(key => {
            Object.keys(value[key]).forEach(day => {
                if (data[day] === undefined) data[day] = {'name': day};
                data[day][key] = value[key][day];
            });
        });

        return Object.values(data)
    }

    render() {
        const {classes} = this.props;
        const dashboard = this.props.dashboard;
        const onClose = this.props.onClose;
        const content = this.props.content;
        const widget = this.props.widget;
        const value = this.props.value;
        const labels = this.props.widget.labels ? this.props.widget.labels.split(',') : [];
        return (
            <Paper className={'dashboard-widget'}
                   style={{minHeight: '400px', textAlign: 'center', alignItems: 'center'}}
                   elevation={1}>
                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                    <EditIcon onClick={this.props.handleEditWidget.bind(this, widget, this.props.index)}/>
                </IconButton>
                <div className="widget-hd">
                    {widget.name}
                </div>
                <ResponsiveContainer width="99%" height={400}>
                    <LineChart
                        data={this.getLineChartData(value)}
                        margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                        <Tooltip/>
                        <Legend iconType="circle" layout="horizontal" verticalAlign="top"
                                align="center"/>
                        {
                            Object.keys(value).map((key, i) =>
                                <Line key={key} type="monotone" dataKey={key}
                                      name={labels[i] !== undefined  ? labels[i] :''} stroke={COLORS[i]}
                                      strokeWidth={3}
                                      activeDot={{r: 8}}/>
                            )
                        }
                    </LineChart>

                </ResponsiveContainer>
            </Paper>
        );
    }
}

LineChartWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LineChartWidget);
