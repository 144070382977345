import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';

const styles = {};

class Configuration extends React.Component {

    render() {
        const {classes} = this.props;
        return (
            <Grid container spacing={16} style={{margin: 0, maxWidth: '400px'}}>
                {this.props.grid && this.props.grid.map((widget, index) =>
                    <Grid item xs={12}>
                        <Paper style={{textAlign: 'center', alignItems: 'center'}} elevation={1}>
                            <div className="widget-hd">
                                {widget.name}
                                <IconButton>
                                    <EditIcon onClick={this.props.handleEditOpen.bind(this, widget, index)}/>
                                </IconButton>
                                {index > 0 &&
                                <IconButton>
                                    <ArrowUpwardIcon onClick={this.props.onMoveWidget.bind(this, index, 1)}/>
                                </IconButton>
                                }
                                {index < this.props.grid.length - 1 &&
                                <IconButton>
                                    <ArrowDownwardIcon onClick={this.props.onMoveWidget.bind(this, index, -1)}/>
                                </IconButton>
                                }
                                <IconButton>
                                    <DeleteIcon onClick={this.props.onDeleteWidget.bind(this, index)}/>
                                </IconButton>
                            </div>
                        </Paper>
                    </Grid>
                )}


                <Grid item xs={12}>
                    <Paper style={{textAlign: 'center', alignItems: 'center'}} elevation={1}>
                        <div className="widget-hd">
                            <IconButton onClick={this.props.handleEditOpen.bind(this, {})}>
                                <AddIcon/>
                            </IconButton>
                            Add new
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

Configuration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Configuration);
