import React from 'react';
import PropTypes from 'prop-types';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import './UserCard.css';

function UserCard(props) {
    const {user, showDialog, setUserId } = props;

    let email_verified;
    if (user.email_verified === 'true') {
        email_verified = <Tooltip title="Verified email"><CheckCircleIcon color="action" /></Tooltip>
    }

    let phone_number_verified;
    if (user.phone_number_verified === 'true') {
        phone_number_verified = <Tooltip title="Verified phone number"><CheckCircleIcon color="action" /></Tooltip>
    }

    return (
        <Paper className='user-card'>
            <div className='user-card__container'>
                <div className='user-card__part_container-tight'>
                    <Avatar>
                        {user.name && user.name.substr(0, 2).toLocaleUpperCase()}
                    </Avatar>
                </div>
                <div class='user-card__part_container'>
                    <Typography className='user-card__part_item'>
                        {user.name}
                    </Typography>
                </div>
                <div class='user-card__part_container'>
                    <Typography className='user-card__part_item user-card__verified'>
                        {email_verified}
                    </Typography>
                    <Typography className='user-card__part_item'>
                        {user.email}
                    </Typography>
                </div>
                <div class='user-card__part_container-last'>
                    <Typography className='user-card__part_item user-card__verified'>
                        {phone_number_verified}
                    </Typography>
                    <Typography className='user-card__part_item'>
                        {user.phone_number}
                    </Typography>
                </div>
                <div className='user-card__part_container-tight-last'>
                    <IconButton aria-label="Edit" onClick={() => {setUserId(user.id)}}>
                        <Tooltip title="Edit">
                            <EditIcon/>
                        </Tooltip>
                    </IconButton>
                    <IconButton aria-label="Delete" onClick={showDialog}>
                        <Tooltip title="Delete">
                            <DeleteIcon/>
                        </Tooltip>
                    </IconButton>
                </div>
            </div>
        </Paper>
    );
};

UserCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default UserCard;
