import React, {Component} from 'react';
import HomeScene from './scenes/Default';
//import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import './App.css';

class App extends Component {
    render() {
        if (this.props.authState === "signedIn") {
            return (
                <div className="App">
                    <HomeScene/>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default App;