import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import GeneralIcon from "../../../Icons/GeneralIcons";

//styles
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import '../index.css';


export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword'];

    this.state = { 
      delivery: null,
      password: '',
      showPassword: false,
    };
  }

  sendView() {
      return (
          <Grid className="signInForm" >
          <Typography 
            variant="body1" 
            className="bold"
          >
          Username
          </Typography>
            <Input
                className="signInInput"
                id="usernamereset"
                autoFocus
                placeholder="Enter your username"
                key="username"
                name="username"
                onChange={this.handleInputChange}
            />
        </Grid>
      );
  }

  submitView() {
      return (
        <Grid className="signInForm" >
              <Typography 
                variant="body1" 
                className="bold"
              >
              Recived code
              </Typography>
              <Input
                  className="signInInput"
                  placeholder='Enter receved code'
                  id="code"
                  key="code"
                  name="code"
                  autoComplete="off"
                  onChange={this.handleInputChange}
              />
              <Typography 
                variant="body1" 
                className="bold"
              >
              New password
              </Typography>
              <Input
                  className="signInInput"
                  id="newpassword"
                  key="password"
                  name="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  placeholder="Ender your new password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowNewPassword}>
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
              />
          </Grid>
      );
  }

handleClickShowNewPassword = () => {
this.setState(state => ({ showPassword: !state.showPassword }));
};

handleChange = prop => event => {
this.setState({ [prop]: event.target.value });
this.handleInputChange(event);
};

  showComponent(theme) {
    // const { authState, hide, authData={} } = this.props;
    // if (hide && hide.includes(ForgotPassword)) { return null; }
    const {authData={} } = this.props;
    return (
        <Grid GenerallIcon container spacing={0} style={{margin: 0, width: '100%',}}  className="signInContainer">
        <Grid item xs={false} sm={1} md={2}></Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
        <Paper xs={12} className="signInWrapper">
          <div className="signInHeader">
            <GeneralIcon className="signInHeaderIcon" name='account' width={100} fill={'#535353'}/>
            <Typography variant="h5" className="bold">Reset</Typography>
            <Typography  variant="body1"  className="bold">Reset your password</Typography>
          </div>
          { this.state.delivery || authData.username ? this.submitView() : this.sendView() }
          <Grid 
            container
            direction="row" 
            justify="flex-end">
            { this.state.delivery || authData.username ? 
                <Button variant="outlined" onClick={(event) => super.submit(event)} className="signInBtn">
                  Reset Password 
                </Button>:
                <Button variant="outlined" onClick={(event) => super.send(event)} className="signInBtn">
                  Send Code 
                </Button>
            }
          </Grid>
          <Grid
                className="signInLinks"
                container
                justify="flex-start"
                alignItems="flex-end"
          >
          { this.state.delivery || authData.username ?
          <Typography variant="body1" className="small">
            Haven't received a code?{" "}
            <Button 
              className="BtnAsLink"
              onClick={(event) => super.send(event)}>
              Resend Code
            </Button>
          </Typography>:
          <Typography variant="body1" className="small">
            Don't whant to reset your password?{" "}
            <Button 
              className="BtnAsLink"
              onClick={() => super.changeState("signIn")}
              >
              Back to Sign In
            </Button>
          </Typography>
          }

          </Grid> 
        </Paper> 
        </Grid> 
        </Grid>

    );
  }
};
