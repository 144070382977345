import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';

const styles = theme => ({
    topBar: {
        display: 'flex',
        padding:'0',
        'height': '82px',
        background: '#fffb4e'

    },
    logo: {
        height: '100%',
        width: '82px',
        background: '#1f1f1f',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-around',
    },
    logo_text: {
        color: '#000',
        'font-family': 'Lato, sans-serif',
        'text-transform': 'uppercase',
        'font-weight': '900',
        'font-style': 'italic',
        'letter-spacing': '0.6px',
        'width': '130px',
        'font-size': '21px',
        'text-align': 'center',
    }
});

class TopNavNotAuth extends Component {
    render() {
        const {classes} = this.props;
        if (this.props.authState === "signedIn") {
            return null;
        } else {
            return (
                <div>
                <CssBaseline/>
                <AppBar position="static">
                    <Toolbar className={classes.topBar}>
                        <a  className={classes.logo} href="/">
                            <img alt='Resultify Insights' src='/static/img/new_r_w.svg' style={{'width': '27px'}}/>
                        </a>
                        <div className={classes.logo_text}>
                        Insights
                        </div>
                    </Toolbar>
                </AppBar>
                </div>
            )
        }
    }
}

export default withStyles(styles)(TopNavNotAuth);