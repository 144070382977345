import React from "react";
import App from "./App";
import TopNavNotAuth from './components/Top/TopNavNotAuth';

// Amplify config
import {SignIn, Greetings, ForgotPassword} from 'aws-amplify-react';
import congnito from './aws-congnito';
import {CustomSignIn} from './components/Forms/Amplify/CustomSignIn';
import {CustomForgotPassword} from './components/Forms/Amplify/CustomForgotPassword';
import {CustomRequireNewPassword} from './components/Forms/Amplify/CustomRequireNewPassword';
import {Authenticator} from "aws-amplify-react/dist/Auth";
import AmplifyTheme from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Theme';
import RequireNewPassword from "aws-amplify-react/dist/Auth/RequireNewPassword";

export const MyToast = {
    'backgroundColor': "#1e1e1e",
    'borderBottom': '2px solid #ffff03',
    'fontFamily': 'Lato, sans-serif',
    'textTransform': 'uppercase'

};

const MyTheme = Object.assign({}, AmplifyTheme, {
    toast: MyToast
});

class AppWithAuth extends React.Component {
    render() {
        return (
            <div>
                <Authenticator theme={MyTheme} hide={[SignIn, Greetings, ForgotPassword, RequireNewPassword]} amplifyConfig={congnito}>
                    <TopNavNotAuth/>
                    <CustomSignIn/>
                    <CustomForgotPassword/>
                    <CustomRequireNewPassword/>
                    <App/>
                </Authenticator>

            </div>
        );
    }
}

export default AppWithAuth;