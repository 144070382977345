import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Popover from '@material-ui/core/Popover';
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';
import copy from "clipboard-copy";
import {
    LineChart,
    ResponsiveContainer,
    Line,
    XAxis,
    Tooltip,
    Legend,
    Area,
    YAxis,
    ComposedChart,
    ReferenceLine,
    Bar,
    CartesianGrid,
    BarChart
} from 'recharts';
import './DashboardSingle.css';
import './../../styles/dashboard.css';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from "@material-ui/core/IconButton";
import DatesPicker from "../../components/Forms/DatesPicker";
import DefaultDrawer from "../../components/Drawer";
import Configuration from "../../components/Dashboard/Configuration";
import DashboardGrid from "../../components/Dashboard/DashboardGrid";
import WidgetConfiguration from "../../components/Dashboard/Configuration/Widget";

const styles = theme => ({
    changeDateBtn: {
        background: theme.palette.primary.main,
        color: '#bebebe',
        'font-family': 'Lato, sans-serif',
        'font-weight': 'bold',
        'text-transform': 'uppercase',
        padding: '8px 20px 7px',
        'font-size': '15px',
        'line-height': '18px',
        'border-radius': '0',
        '&:hover': {
            'color': '#fff',
            background: '#3f3f3f'
        }
    },
    editPenIcon: {
        float: 'right',
        right: 0,
        position: 'relative',
        top: 0,
    }
});

let api_base = process.env.REACT_APP_API_URL;

const formatBigNumbers = n => {
    if (n < 1e6) return n;
    //if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12 && n < 1e15) return +(n / 1e12).toFixed(1) + "T";
    if (n >= 1e15 && n < 1e18) return +(n / 1e15).toFixed(1) + "P";
    if (n >= 1e18) return +(n / 1e18).toFixed(1) + "E";
};

const sortOnKeys = (dict) => {
    let sorted = [];
    for (let key in dict) {
        sorted[sorted.length] = key;
    }
    sorted.sort();

    let tempDict = {};
    for (let i = 0; i < sorted.length; i++) {
        tempDict[sorted[i]] = dict[sorted[i]];
    }

    return tempDict;
};

const checkAndFormatDate = (date) => {
    let dateObject = typeof date === "object" ? date : new Date(date);
    if (dateObject >= new Date()) return false;

    return `${dateObject.toISOString().substring(0, 10)}`;
};

const formatDate = (date) => {
    let dateObject = typeof date === "object" ? date : new Date(date);
    return `${dateObject.toISOString().substring(0, 10)}`;
};

const formatTodayDate = () => {
    let dateObject = new Date();

    return `${dateObject.toISOString().substring(0, 10)}`;
};
const formatEndDate = () => {
    let dateObject = new Date();
    dateObject.setDate(dateObject.getDate() - 30);

    return `${dateObject.toISOString().substring(0, 10)}`;
};

const COLORS = ['#ffde28', '#000000', '#ce3ac3'];

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload) {
        return (
            <div className="tooltip" style={{borderColor: payload[0].color}}>
                {payload.map(item => {
                    // if (item.value <= 0) return null;
                    return <p key={item.name} className="indicator">
                                <span className="number">
                                    {`${formatBigNumbers(item.value)}`}
                                </span>
                        <span className="label">
                                    {item.name}
                                </span>
                    </p>
                })}
                <div className="date">
                    {payload[0].payload.name}
                </div>
            </div>
        );
    }

    return null;
};

const ReferenceLabel = (props) => {
    const {
        fill, name, textAnchor,
        fontSize, viewBox, dy, dx,
    } = props;
    const x = viewBox.width + viewBox.x + 20;
    const y = viewBox.y + 50;
    return (
        <text
            x={x} y={y}
            dy={dy}
            dx={dx}
            fill={fill}
            fontSize={fontSize || 16}
            textAnchor={textAnchor}>
            {name}
        </text>
    )
}

const PopoverPopupState = (props) => {

    return (
        <PopupState variant="popover">
            {popupState => (
                <div style={{marginLeft: '5px'}}>
                    <Button className={props.className}
                            color="primary"
                            variant="outlined"
                            {...bindTrigger(popupState)}
                    >
                        Share
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography style={{padding: 10}}>
                            <IconButton
                                onClick={() => copy(`${document.location.origin}/share/${props.did}/${props.company}`)}>
                                <LinkIcon/>
                            </IconButton>
                            {document.location.origin}/share/{props.did}/{props.company}
                        </Typography>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}

class Dashboard extends Component {
    state = {
        data: {},
        databytrigger: {},
        dashboard: {},
        custom_data: {},
        open: false,
        end: formatTodayDate(),
        start: formatEndDate(),
        share: true,
        error: false,
        sums: {},
        drawerOpen: false,
        drawerContent: '',
        drawer: {
            name: '',

        }
    };

    componentDidMount() {
        this.props.toggleLoading(true);
        if (this.props.match.params.cmp !== undefined) {
            localStorage.setItem('selectedClient', this.props.match.params.cmp);
            fetch(`${api_base}/clients/${localStorage.selectedClient}`)
                .then(response => response.json())
                .then(data => {
                    this.setState({client: data.body});
                })
            this.setState({share: false});
        }
        fetch(`${api_base}/dashboards/${this.props.match.params.dashboard}?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                    this.setState({dashboard: data.body});
                    fetch(`${api_base}/connections/?client=${localStorage.selectedClient}`)
                        .then(response => response.json())
                        .then(data => {
                                fetch(`${api_base}/connections/dashboard/${this.state.dashboard.Dashboard}/data?client=${localStorage.selectedClient}`)
                                    .then(response => response.json())
                                    .then(data => {
                                        this.setState({data: data.body});

                                    })
                                    .catch(error => {
                                        this.setState({error: true});
                                    });
                                fetch(`${api_base}/connections/dashboard/${this.state.dashboard.Dashboard}/databytrigger?client=${localStorage.selectedClient}`)
                                    .then(response => response.json())
                                    .then(data => {
                                        this.setState({databytrigger: data.body});
                                        this.props.toggleLoading(false);
                                    })
                                    .catch(error => {
                                        this.setState({error: true});
                                    });
                                fetch(`${api_base}/settings/values?client=${localStorage.selectedClient}`)
                                    .then(response => response.json())
                                    .then(data => {
                                            this.setState({values: data.body});
                                        }
                                    );
                                fetch(`${api_base}/settings/costs?client=${localStorage.selectedClient}`)
                                    .then(response => response.json())
                                    .then(data => {
                                            this.setState({costs: data.body});
                                        }
                                    );
                                fetch(`${api_base}/connections/custom/${this.state.dashboard.type}/data?client=${localStorage.selectedClient}`)
                                    .then(response => response.json())
                                    .then(data => {
                                            this.setState({data: data.body});
                                            setTimeout(() => this.calculateValuesForMetrics(), 300);

                                        }
                                    );

                            }
                        )
                }
            );
    }

    fetchData(start, end) {
        this.props.toggleLoading(true);
        this.setState({start: start, end: end});
        fetch(`${api_base}/connections/dashboard/${this.state.dashboard.Dashboard}/databytrigger?client=${localStorage.selectedClient}&start=${start.substr(0, 10)}&end=${end.substr(0, 10)}`)
            .then(response => response.json())
            .then(data => {
                this.setState({databytrigger: data.body});
                this.props.toggleLoading(false);
            })
            .catch(error => {
                this.setState({error: true});
            });
        fetch(`${api_base}/connections/dashboard/${this.state.dashboard.Dashboard}/data?client=${localStorage.selectedClient}&start=${start.substr(0, 10)}&end=${end.substr(0, 10)}`)
            .then(response => response.json())
            .then(data => {
                this.setState({data: data.body});
                this.calculateValuesForMetrics();
                this.props.toggleLoading(false);
            })
            .catch(error => {
                this.setState({error: true});
            });
    }

    getLineChartData() {
        let data = {};
        let args = [...arguments];
        for (let key in args) {
            if (this.state.data[args[key]] !== undefined) {
                this.state.data[args[key]]['day']['values'].forEach(function (item) {
                    let formattedDay = checkAndFormatDate(item['end_time']);
                    if (formattedDay) {
                        if (data[formattedDay] === undefined) data[formattedDay] = {'name': formattedDay};
                        data[formattedDay][args[key]] = item['value'];
                    }
                });
            }
        }

        return Object.values(data)
    }

    getNumberData(key) {
        let number = 0;
        if (this.state.data[key] !== undefined) {
            number = this.state.data[key]['week']['values'].find(item => formatDate(item['end_time']) === formatTodayDate()).value
        }

        return number
    }

    getLabel(label, period) {
        return Object.keys(this.state.data).length > 0 ? this.state.data[label][period]['title'] : label
    }

    calculateSum(goal) {
        let sum = 0;
        let daily = {};
        if (goal.network !== undefined) {
            this.state.data[goal.network].forEach(row => {
                if (goal.network === 'facebook') {
                    row[goal.trigger]['day']['values'].forEach(item => {
                        let formattedDay = checkAndFormatDate(item['end_time']);
                        if (formattedDay) {
                            if (daily[formattedDay] === undefined) daily[formattedDay] = {
                                'name': formattedDay,
                                'value': 0
                            };
                            daily[formattedDay]['value'] += parseInt(item['value']) * parseInt(goal.value);
                            sum += parseInt(item['value']) * parseInt(goal.value);
                        }
                    });
                }
                if (goal.network === 'google_analytics') {
                    if (goal.trigger === 'ga:uniqueEvents') {
                        //let event = row.events[0].find(event => event['ga:eventCategory'] === goal.event);
                        //sum += parseInt(event[goal.trigger]);

                        let event_daily = row.events[1].filter(event => event['ga:eventCategory'] === goal.event);
                        if ((goal.event_label !== '') && (goal.event_label !== undefined)) {
                            event_daily = row.events_detailed[1].filter(event => ((event['ga:eventLabel'] === goal.event_label) && (event['ga:eventCategory'] === goal.event)));
                        }

                        event_daily.forEach(item => {
                            let formattedDay = checkAndFormatDate(
                                [
                                    item['ga:date'].slice(0, 4),
                                    item['ga:date'].slice(4, 6),
                                    item['ga:date'].slice(6, 8)
                                ].join('-')
                            );
                            if (daily[formattedDay] === undefined) daily[formattedDay] = {
                                'name': formattedDay,
                                'value': 0
                            };
                            daily[formattedDay]['value'] += parseInt(item[goal.trigger]) * parseInt(goal.value);
                            sum += parseInt(item[goal.trigger]) * parseInt(goal.value);
                        });
                    } else {
                        sum += parseInt(row['total'][0][goal.trigger]);
                        row['daily'].forEach(item => {
                            let formattedDay = checkAndFormatDate(
                                [
                                    item['ga:date'].slice(0, 4),
                                    item['ga:date'].slice(4, 6),
                                    item['ga:date'].slice(6, 8)
                                ].join('-')
                            );
                            if (daily[formattedDay] === undefined) daily[formattedDay] = {
                                'name': formattedDay,
                                'value': 0
                            };
                            daily[formattedDay]['value'] += parseInt(item[goal.trigger]) * parseInt(goal.value);
                            //    sum += parseInt(item[goal.trigger]) * parseInt(goal.value);
                        });
                    }
                }
            });
        }

        return {'total': sum, 'daily': daily};
    }

    calculateValuesForMetrics() {
        try {
            let custom_data = {};
            let sums = {};
            let fulfills = {};
            this.state.values.forEach(goal => {
                sums[goal.trigger + goal.name] = this.calculateSum(goal);
                fulfills[goal.trigger + goal.name] = sums[goal.trigger + goal.name].total / parseInt(goal.value);
            });

            let start_time = new Date(this.state.start);
            let end_time = new Date(this.state.end);

            custom_data['value'] = {'total': 0, daily: {}};
            custom_data['cost'] = {'total': 0, daily: {}};
            custom_data['mroi'] = {'total': 0, daily: {}};
            custom_data['roas'] = {'total': 0, daily: {}};
            custom_data['value_ga_events'] = {
                total: Object.keys(fulfills)
                    .filter(key => key.indexOf('ga:uniqueEvent') !== -1)
                    .map(key => fulfills[key]) || []
                    .reduce((a, b) => a + b),
                daily: {}
            };
            custom_data['all_daily'] = {};

            for (let i = start_time.getTime(); i <= end_time.getTime(); i += 86400000) {
                custom_data['value']['daily'][formatDate(new Date(i))] = 0;
                custom_data['mroi']['daily'][formatDate(new Date(i))] = 0;
                custom_data['roas']['daily'][formatDate(new Date(i))] = 0;
                custom_data['cost']['daily'][formatDate(new Date(i))] = 0;
                custom_data['all_daily'][formatDate(new Date(i))] = {
                    'name': formatDate(new Date(i)),
                    'value': 0,
                    'cost': 0,
                    'mroi': 0,
                    'roas': 0,
                };
            }

            Object.keys(sums).forEach((key) => {
                custom_data['value']['total'] += sums[key]['total'];
                Object.keys(sums[key]['daily']).forEach((day) => {
                    custom_data['value']['daily'][day] = sums[key]['daily'][day]['value'];
                    custom_data['all_daily'][day]['value'] += sums[key]['daily'][day]['value'];
                });
            });
            custom_data['all_daily'] = sortOnKeys(custom_data['all_daily']);

            this.state.costs.forEach(cost => {
                if (cost.type === 'onetime') {
                    if ((formatDate(cost.start_date) <= formatDate(this.state.end))) {
                        custom_data['cost']['total'] += parseInt(cost.cost);
                        start_time = new Date(this.state.start);
                        end_time = new Date(this.state.end);

                        for (let i = start_time.getTime(); i <= end_time.getTime(); i += 86400000) {
                            if (formatDate(new Date(i)) >= formatDate(new Date(cost.start_date))) {
                                if (custom_data['cost']['daily'][formatDate(new Date(i))] === undefined) {
                                    custom_data['cost']['daily'][formatDate(new Date(i))] = 0;
                                }
                                custom_data['cost']['daily'][formatDate(new Date(i))] += parseInt(cost.cost);
                                custom_data['all_daily'][formatDate(new Date(i))]['cost'] += parseInt(cost.cost);
                            }
                        }

                    }
                } else {
                    // let n_days = (new Date(this.state.end) - new Date(this.state.end)) / 1000 / 86400;

                }
            });

            if (custom_data['cost']['total'] > 0) {

                custom_data['mroi']['total'] = Math.round(
                    (custom_data['value']['total'] - custom_data['cost']['total'])
                    / custom_data['cost']['total'] * 100
                );

                let agg_value = 0;
                Object.keys(custom_data['cost']['daily']).forEach(day => {
                    agg_value += custom_data['value']['daily'][day];
                    custom_data['roas']['daily'][day] = agg_value - custom_data['cost']['daily'][day];
                    custom_data['all_daily'][day]['roas'] = custom_data['roas']['daily'][day];
                });

            }

            this.setState({custom_data: custom_data, sums: sums});
        } catch (e) {
            console.log(e)
        }
    }

    maxNumber() {
        let sums = [];
        if (this.state.data.daily !== undefined) {
            this.state.data.daily.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (['ga:date'].indexOf(key) === -1) {
                        sums.push(parseInt(item[key]));
                    }
                })
            });
        }
        return sums.length === 0 ? 0 : sums.reduce(function (a, b) {
            return Math.max(a, b);
        });
    }

    toggleDrawer = (open) => () => {
        this.setState({
            drawerOpen: open
        });
    };

    setDrawerContent(content) {
        this.setState({
            drawerOpen: true,
            drawerContent: content
        });
    };

    setConfigureView() {
        this.setDrawerContent(
            <Configuration
                grid={this.state.dashboard.grid === undefined ? [] : this.state.dashboard.grid.items}
                handleEditOpen={this.handleEditOpen.bind(this)}
                onSaveWidget={this.onSaveWidget.bind(this)}
                onDeleteWidget={this.onDeleteWidget.bind(this)}
                onMoveWidget={this.onMoveWidget.bind(this)}
            />
        );
    }

    handleEditOpen(widget, index) {
        this.setDrawerContent(
            <WidgetConfiguration
                widget={widget}
                onSaveWidget={this.onSaveWidget.bind(this)}
                index={index}
                values={
                    Object.keys(Object.assign(this.state.databytrigger, this.state.custom_data))
                        .filter(item => ['roas', 'mroi', 'value_ga_events', 'all_daily'].indexOf(item) === -1)
                }
            />
        );
    }

    onSaveWidget(values, index) {
        console.log(index);
        let data = this.state.dashboard;
        if (data.grid === undefined) {
            data['grid'] = {'items': [values]};
        } else {
            if ((index === undefined) || (typeof index === 'object')) {
                if (data['grid']['items'] == undefined) data['grid'] = {'items': []};
                data['grid']['items'].push(values);
            } else {
                data['grid']['items'][index] = values;
            }
        }
        this.setState({dashboard: data, drawerOpen: false, drawerContent: <div></div>});
        fetch(`${api_base}/dashboards/${this.state.dashboard.Dashboard}/updategrid?client=${localStorage.selectedClient}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
            .then(response => response.json())
            .then(data => {
                //
            })
    }

    onDeleteWidget(index) {
        let data = this.state.dashboard;
        data.grid.items.splice(index, 1);
        this.setState({dashboard: data});
        fetch(`${api_base}/dashboards/${this.state.dashboard.Dashboard}/updategrid?client=${localStorage.selectedClient}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
            .then(response => response.json())
            .then(data => {
                //
            });
        this.setConfigureView();
    }

    onMoveWidget(index, direction) {

        let data = this.state.dashboard;
        let swap = data.grid.items[index - direction];
        data.grid.items[index - direction] = data.grid.items[index];
        data.grid.items[index] = swap;
        this.setState({dashboard: data});

        fetch(`${api_base}/dashboards/${this.state.dashboard.Dashboard}/updategrid?client=${localStorage.selectedClient}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
            .then(response => response.json())
            .then(data => {
                //
            });

    }

    render() {
        const {classes} = this.props;
        const dashboard = this.state.dashboard;
        const maxNumber = this.maxNumber();
        return (
            <div className={classes.contentWrapper}>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5" style={{flexGrow: 1}}>
                                {this.state.dashboard.name} Dashboard
                            </Typography>
                            <div style={{textAlign: 'right'}}>
                                <ClickAwayDatePicker classes={this.props.classes} onChange={(a, b) => {
                                    this.fetchData(a, b)
                                }}/>
                            </div>
                            {this.state.share && <PopoverPopupState
                                className={this.props.classes.changeDateBtn}
                                did={dashboard.Dashboard}
                                company={localStorage.selectedClient}
                            />}
                            <Button
                                className={this.props.classes.changeDateBtn}
                                color="primary"
                                variant="outlined"
                                autoFocus
                                style={{marginLeft: 5}}
                                onClick={this.setConfigureView.bind(this)}> Configure
                            </Button>
                        </div>
                        <DashboardGrid
                            dashboard={this.state.dashboard}
                            data={Object.assign(this.state.databytrigger, this.state.custom_data)}
                            handleEditWidget={this.handleEditOpen.bind(this)}
                            sums={this.state.sums}
                            toggleLoading={this.props.toggleLoading}
                        />
                    </Grid>
                </Grid>
                <DefaultDrawer
                    open={this.state.drawerOpen}
                    name={this.state.drawer.name}
                    onClose={this.toggleDrawer(false)}
                    content={this.state.drawerContent}
                />
            </div>
        )
        if (dashboard.type === 'facebook') {
            return (
                <div className={classes.contentWrapper}>
                    <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                        <Grid item xs={12}>
                            <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h5" style={{flexGrow: 1}}>
                                    {this.state.dashboard.name} Dashboard
                                </Typography>
                                <div style={{textAlign: 'right'}}>
                                    <ClickAwayDatePicker classes={this.props.classes} onChange={(a, b) => {
                                        this.fetchData(a, b)
                                    }}/>
                                </div>
                                {this.state.share && <PopoverPopupState
                                    className={this.props.classes.changeDateBtn}
                                    did={dashboard.Dashboard}
                                    company={localStorage.selectedClient}
                                />}
                                <Button
                                    className={this.props.classes.changeDateBtn}
                                    color="primary"
                                    variant="outlined"
                                    autoFocus
                                    style={{marginLeft: 5}}
                                    onClick={this.setConfigureView.bind(this)}> Configure
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <div className="widget-hd">
                                    Facebook overview
                                </div>
                                <ResponsiveContainer width="99%" height={300}>
                                    <LineChart
                                        data={this.getLineChartData('page_views_total', 'page_video_views', 'page_actions_post_reactions_like_total')}
                                        margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                        <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                                        <Tooltip/>
                                        <Legend iconType="circle" layout="horizontal" verticalAlign="top"
                                                align="center"/>
                                        <Line type="monotone" dataKey="page_views_total"
                                              name={this.getLabel('page_views_total', 'day')} stroke={COLORS[0]}
                                              strokeWidth={3}
                                              activeDot={{r: 8}}/>
                                        <Line type="monotone" dataKey="page_video_views"
                                              name={this.getLabel('page_video_views', 'day')} stroke={COLORS[1]}
                                              strokeWidth={3}
                                              activeDot={{r: 8}}/>
                                        <Line type="monotone" dataKey="page_actions_post_reactions_like_total"
                                              name={this.getLabel('page_actions_post_reactions_like_total', 'day')}
                                              stroke={COLORS[2]} strokeWidth={3}
                                              activeDot={{r: 8}}/>
                                    </LineChart>

                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    {this.getLabel('page_impressions_unique', 'week')}
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.getNumberData('page_impressions_unique')}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    {this.getLabel('page_post_engagements', 'week')}
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.getNumberData('page_post_engagements')}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    {this.getLabel('page_video_views', 'week')}
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.getNumberData('page_video_views')}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    {this.getLabel('page_fan_adds_unique', 'week')}
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.getNumberData('page_fan_adds_unique')}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <DefaultDrawer
                        open={this.state.drawerOpen}
                        name={this.state.drawer.name}
                        onClose={this.toggleDrawer(false)}
                        content={this.state.drawerContent}
                    />
                </div>
            );
        }
        if (dashboard.type === 'google_analytics') {
            return (
                <div className={classes.contentWrapper}>
                    <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                        <Grid item xs={12}>
                            <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h5" style={{flexGrow: 1}}>
                                    {this.state.dashboard.name} Dashboard
                                </Typography>
                                <div style={{textAlign: 'right'}}>
                                    <ClickAwayDatePicker classes={this.props.classes} onChange={(a, b) => {
                                        this.fetchData(a, b)
                                    }}/>
                                </div>
                                {this.state.share && <PopoverPopupState
                                    className={this.props.classes.changeDateBtn}
                                    did={dashboard.Dashboard}
                                    company={localStorage.selectedClient}
                                />}
                                <Button
                                    className={this.props.classes.changeDateBtn}
                                    color="primary"
                                    variant="outlined"
                                    autoFocus
                                    style={{marginLeft: 5}}
                                    onClick={this.setConfigureView.bind(this)}> Configure
                                </Button>
                            </div>
                        </Grid>
                        {this.state.error ?
                            <Grid item xs={12}>
                                <Paper style={{minHeight: '50px', textAlign: 'center', alignItems: 'center'}}
                                       elevation={1}>
                                    <div className="widget-hd">
                                        Unfortunately, it's not possible to fetch the data at the moment.
                                    </div>
                                </Paper>
                            </Grid>
                            : null}
                        <Grid item xs={12}>

                            <Paper style={{minHeight: '440px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <div className="widget-hd">
                                    Google analytics overview
                                </div>
                                <ResponsiveContainer width="99%" height={400}>
                                    <LineChart
                                        data={this.state.data.daily}
                                        margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                        <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                                        <YAxis hide={true} type="number" axisLine={false} tickLine={false}
                                               domain={[0, maxNumber]}/>
                                        <Tooltip/>
                                        <Legend iconType="circle" layout="horizontal" verticalAlign="top"
                                                align="center"/>
                                        <Line type="monotone" dataKey="ga:sessions"
                                              name="Sessions" stroke={COLORS[0]}
                                              strokeWidth={3}
                                              activeDot={{r: 8}}/>
                                        <Line type="monotone" dataKey="ga:pageviews"
                                              name="Pageviews" stroke={COLORS[1]}
                                              strokeWidth={3}
                                              activeDot={{r: 8}}/>
                                        <Line type="monotone" dataKey="ga:totalEvents"
                                              name="Total events"
                                              stroke={COLORS[2]} strokeWidth={3}
                                              activeDot={{r: 8}}/>
                                    </LineChart>

                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}
                                            onClick={this.handleEditOpen.bind(this)}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Sessions
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.data.total && this.state.data.total.length > 0 && this.state.data.total[0]['ga:sessions']}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Pageviews
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.data.total && this.state.data.total.length > 0 && this.state.data.total[0]['ga:pageviews']}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Bounces
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.data.total && this.state.data.total.length > 0 && this.state.data.total[0]['ga:bounces']}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Total events
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.data.total && this.state.data.total.length > 0 && this.state.data.total[0]['ga:totalEvents']}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        {this.state.data.events &&
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{flexGrow: 1}}>
                                Interactions
                            </Typography>
                        </Grid>
                        }
                        {this.state.data.events && this.state.data.events[0].map(ga_event =>
                            <Grid item xs={3} key={ga_event['ga:eventCategory']}>
                                <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                       style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                       elevation={1}>
                                    <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                        <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                    </IconButton>
                                    <div className="widget-hd">
                                        {ga_event['ga:eventCategory']}
                                    </div>
                                    <div className="widget-body">
                                        <div className="widget-value">
                                            {ga_event['ga:uniqueEvents']}
                                        </div>
                                        <div className="widget-delta">
                                            -
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    <DefaultDrawer
                        open={this.state.drawerOpen}
                        name={this.state.drawer.name}
                        onClose={this.toggleDrawer(false)}
                        content={this.state.drawerContent}
                    />
                </div>
            );
        }
        if (dashboard.type === 'value' || dashboard.type === 'summary') {
            let data = [], bardata = [{'name': 'Values'}];
            if (this.state.custom_data.all_daily) {
                data = Object.values(this.state.custom_data.all_daily);
                Object.keys(this.state.sums).map((key) => {
                    bardata[0][key] = this.state.sums[key].total;
                    return;
                });
            }
            const gradientOffset = () => {
                const dataMax = Math.max(...data.map(i => i.roas));
                const dataMin = Math.min(...data.map(i => i.roas));

                if (dataMax <= 0) {
                    return 0;
                }
                if (dataMin >= 0) {
                    return 1;
                }

                return dataMax / (dataMax - dataMin);
            };
            const off = gradientOffset();

            return (
                <div className={classes.contentWrapper}>
                    <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                        {!this.state.share &&
                        <Grid item xs={12}>
                            {this.state.client &&
                            this.state.client.logo && this.state.client.logo !== ' ' ?
                                <div className='logo-holder'>
                                    <img className='logo' src={this.state.client.logo} alt="Logo"/>
                                </div> :
                                <div className='logo-holder'>
                                    {this.state.client.name}
                                </div>
                            }
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h5" style={{flexGrow: 1}}>
                                    {this.state.dashboard.name} Dashboard
                                </Typography>
                                <div style={{textAlign: 'right'}}>
                                    <ClickAwayDatePicker classes={this.props.classes} onChange={(a, b) => {
                                        this.fetchData(a, b)
                                    }}/>
                                </div>
                                {this.state.share && <PopoverPopupState
                                    className={this.props.classes.changeDateBtn}
                                    did={dashboard.Dashboard}
                                    company={localStorage.selectedClient}
                                />}
                                <Button
                                    className={this.props.classes.changeDateBtn}
                                    color="primary"
                                    variant="outlined"
                                    autoFocus
                                    style={{marginLeft: 5}}
                                    onClick={this.setConfigureView.bind(this)}> Configure
                                </Button>
                            </div>
                        </Grid>
                        {this.state.error ?
                            <Grid item xs={12}>
                                <Paper style={{minHeight: '50px', textAlign: 'center', alignItems: 'center'}}
                                       elevation={1}>
                                    <div className="widget-hd">
                                        Unfortunately, it's not possible to fetch the data at the moment.
                                    </div>
                                </Paper>
                            </Grid>
                            : null}
                        <Grid item xs={12}>
                            <Paper style={{minHeight: '440px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Value overview
                                </div>
                                <ResponsiveContainer width="99%" height={400}>
                                    <ComposedChart
                                        data={this.state.custom_data.all_daily && Object.values(this.state.custom_data.all_daily)}
                                        margin={{top: 5, right: 30, left: 20, bottom: 5}}
                                    >
                                        <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                                        {this.state.costs && this.state.costs.map(
                                            cost => <ReferenceLine
                                                className="reference-label"
                                                key={cost.name}
                                                x={formatDate(cost.start_date)}
                                                label={<ReferenceLabel {...cost} />}
                                                stroke="rgba(0,0,0,0.5)"
                                                strokeWidth={2}
                                                strokeDasharray="20 20"
                                            />
                                        )}
                                        <Tooltip content={<CustomTooltip/>}/>
                                        <Legend iconType="circle" layout="horizontal" verticalAlign="top"
                                                align="center"/>
                                        <defs>
                                            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset={off} stopColor="rgba(0,128,0,0.8)" stopOpacity={1}/>
                                                <stop offset={off} stopColor="rgba(255,0,0,0.8)" stopOpacity={1}/>
                                            </linearGradient>
                                        </defs>
                                        <Area type="monotone" dataKey="roas"
                                              name="Return on spend"
                                              stroke="url(#splitColor)"
                                              fill="url(#splitColor)"
                                              strokeWidth={3}
                                              activeDot={{r: 10}}/>
                                        <Line type="monotone" dataKey="value"
                                              name="Value" stroke={COLORS[0]}
                                              strokeWidth={3}
                                              activeDot={{r: 10}}/>
                                    </ComposedChart>

                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Value
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.custom_data['value'] && formatBigNumbers(this.state.custom_data['value']['total'])} SEK
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Cost
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.custom_data['cost'] && formatBigNumbers(this.state.custom_data['cost']['total'])} SEK
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    MROI
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.custom_data['mroi'] && formatBigNumbers(this.state.custom_data['mroi']['total'])} %
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Events
                                </div>
                                <div className="widget-body">
                                    <div className="widget-value">
                                        {this.state.custom_data['value_ga_events'] && formatBigNumbers(this.state.custom_data['value_ga_events']['total'])}
                                    </div>
                                    <div className="widget-delta">
                                        -
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={'dashboard-widget dashboard-widget__type-number'}
                                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                                   elevation={1}>
                                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                                    <EditIcon onClick={this.handleEditOpen.bind(this)}/>
                                </IconButton>
                                <div className="widget-hd">
                                    Value split
                                </div>
                                <BarChart
                                    width={400}
                                    height={300}
                                    data={bardata}
                                    layout="vertical"
                                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                                >
                                    <XAxis type="number"/>
                                    <YAxis type="category" dataKey="name"/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    <Legend/>
                                    {this.state.values && this.state.values.map((item) => {
                                        return <Bar dataKey={item.trigger + item.name} name={item.name}
                                                    fill={COLORS[this.state.values.indexOf(item)]}/>
                                    })}
                                </BarChart>
                            </Paper>
                        </Grid>
                    </Grid>
                    <DefaultDrawer
                        open={this.state.drawerOpen}
                        name={this.state.drawer.name}
                        onClose={this.toggleDrawer(false)}
                        content={this.state.drawerContent}
                    />
                </div>
            );
        }
    }
}

class ClickAwayDatePicker extends Component {
    state = {
        open: false,
        text: 'Last 30 days',
        start: '',
        end: ''
    };

    handleClick = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleClickAway = () => {
        this.setState({
            open: false,
        });
    };

    getSelected = (selected, startDate, endDate) => {
        this.setState({
            text: selected,
            start: startDate,
            end: endDate
        });
        this.props.onChange(startDate, endDate);
    }

    render() {
        const {classes} = this.props;
        const {open, text} = this.state;

        return (
            <div>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div>
                        {!open ? (
                            <Button
                                className={classes.changeDateBtn}
                                color="primary"
                                variant="outlined"
                                autoFocus
                                onClick={this.handleClick}> {text}
                            </Button>
                        ) : null}
                        {open ? (
                            <DatesPicker selectedDates={this.getSelected}/>
                        ) : null}
                    </div>
                </ClickAwayListener>
            </div>
        );
    }
}

Dashboard.propTypes = {};
Dashboard.defaultProps = {};

export default withStyles(styles)(Dashboard);