import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Values from '../Settings/Value';
import Costs from '../Settings/Cost';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import SocialIcon from "../../components/Icons/SocialIcons";
import Share from "@material-ui/icons/Share";
import DefaultDrawer from "../../components/Drawer";

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        paddingLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerContainer: {
        padding: 20
    },
    formGroup: {
        padding: 10
    }
});

class Settings extends Component {
    state = {
        drawerOpen: false,
        drawerContent: '',
        drawer: {
            name: '',

        }
    };

    toggleDrawer = (open) => () => {
        this.setState({
            drawerOpen: open
        });
    };

    setDrawerContent(content) {
        this.setState({
            drawerOpen: true,
            drawerContent: content
        });
    };

    onDrawerSubmit(content) {
        this.setState({
            drawerOpen: false,
            drawerContent: ''
        });
    };

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5">
                                Settings
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Values setDrawerContent={this.setDrawerContent.bind(this)}
                        onDrawerSubmit={this.onDrawerSubmit.bind(this)}/>
                <Costs setDrawerContent={this.setDrawerContent.bind(this)}
                       onDrawerSubmit={this.onDrawerSubmit.bind(this)}/>
                <DefaultDrawer
                    open={this.state.drawerOpen}
                    name={this.state.drawer.name}
                    onClose={this.toggleDrawer(false)}
                    content={this.state.drawerContent}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Settings);