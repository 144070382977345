import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
    addBtn: {
        background: theme.palette.primary.main,
        color: '#fff',
        padding: '8px 20px 7px',
        'border-radius': '0',
        '&:hover': {
            background: '#3f3f3f',
            'color': '#fff'
        }
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit*-0.5,
    },
});

class AddButton extends React.Component {
    state = {
        open: false,
    };

    render() {
        const name = this.props.name;

        return (
            <Button
                className={this.props.classes.addBtn}
                onClick={this.props.onClick}
                autoFocus
            >
            {name}
            <AddIcon className={this.props.classes.rightIcon} />
            </Button>
        );
    }
}

AddButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddButton);
