import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";


const styles = theme => ({
    editPenIcon: {
        float: 'right',
        right: 0,
        position: 'relative',
        top: 0,
    }
});
const COLORS = [
    '#ffde28', '#000000', '#ce3ac3',
    '#ffde28', '#000000', '#ce3ac3',
    '#ffde28', '#000000', '#ce3ac3',
    '#ffde28', '#000000', '#ce3ac3'
];

class BarChartWidget extends React.Component {
    state = {};

    getBarChardData(value) {
        let data = [{}];
        Object.keys(value).map((key) => {
            data[0][key] = value[key];
            return;
        });
        return data;
    }

    render() {
        const {classes} = this.props;
        const dashboard = this.props.dashboard;
        const onClose = this.props.onClose;
        const content = this.props.content;
        const widget = this.props.widget;
        const value = this.props.value;
        const labels = this.props.widget.labels ? this.props.widget.labels.split(',') : []
        console.log(value)
        return (
            <Paper className={'dashboard-widget'}
                   style={{minHeight: '400px', textAlign: 'center', alignItems: 'center'}}
                   elevation={1}>
                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                    <EditIcon onClick={this.props.handleEditWidget.bind(this, widget, this.props.index)}/>
                </IconButton>
                <div className="widget-hd">
                    {widget.name}
                </div>
                <ResponsiveContainer width="99%" height={400}>
                    <BarChart
                        data={this.getBarChardData(value)}
                        layout="vertical"
                        margin={{top: 5, right: 30, left: 20, bottom: 5}}
                    >
                        <XAxis type="number"/>
                        <YAxis type="category" dataKey="name"/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Legend/>

                        {
                            Object.keys(value).map((key, i) =>
                                <Bar dataKey={key}
                                     name={labels[i] !== undefined  ? labels[i] : key}
                                     fill={COLORS[i]}
                                     />
                            )
                        }

                    </BarChart>

                </ResponsiveContainer>
            </Paper>

        );
    }
}

BarChartWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BarChartWidget);


/*


            <Paper className={'dashboard-widget'}
                   style={{minHeight: '220px', textAlign: 'center', alignItems: 'center'}}
                   elevation={1}>
                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                    <EditIcon onClick={this.props.handleEditWidget.bind(this, widget, this.props.index)}/>
                </IconButton>
                <div className="widget-hd">
                    {widget.name}
                </div>
            </Paper>


  <BarChart
                    data={labels.map(item => {
                        return {'name': item}
                    })}
                    layout="vertical"
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                >
                    <XAxis type="number"/>
                    <YAxis type="category" dataKey="name"/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    <Legend/>
                    {this.state.values && this.state.values.map((item) => {
                        return <Bar dataKey={item.trigger + item.name} name={item.name}
                                    fill={COLORS[this.state.values.indexOf(item)]}/>
                    })}
                </BarChart>

*/