import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import SocialIcon from "../../components/Icons/SocialIcons";
import './../../styles/dashboard.css';
import Connector from '../../components/Connection';
import Share from '@material-ui/icons/Share';
import DefaultDrawer from "../../components/Drawer";
import Select from 'react-select';
import AddButton from "../../components/AddButton";
import Values from "../Settings/Value";

const customSelectStyles = {
    control: () => ({
        display: "flex",
        alignItems: "center",
        border: 0,
        height: "auto",
        background: "transparent",
        position: "relative",
        "&:hover": {
            boxShadow: "none",
        },
        "&:after": {
            content: "''",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:after": {
            borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
        },
    }),
    menu: () => ({
        backgroundColor: "white",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        position: "absolute",
        left: 0,
        top: "calc(100% + 1px)",
        width: "100%",
        zIndex: 200,
        maxHeight: 200
    }),
    valueContainer: () => ({
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "6px 0 7px",
    }),
    menuList: () => ({
        maxHeight: 200,
        overflowY: "auto",
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    singleValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    multiValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
        backgroundColor: "hsl(0,0%,90%)",
        boxSizing: "border-box",
        display: "flex",
        borderRadius: "2px",
    })
};

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    input: {
        paddingRight: '8px',
        paddingLeft: '8px'
    },
    drawer: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
    },
    drawerContainer: {
        padding: 10,
    },
    drawerContainerWrapper: {
        padding: 10,
        display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
        drawerContainer: {
            padding: 0
        },
    },
    loadingContainer: {
        width: '100%',
        textAlign: 'center'
    },
    formGroup: {
        padding: 10
    },
    formControl: {
        width: '100%',
    },
    avatar: {
        margin: 8,
    },
    connectionButton: {
        display: 'flex',
        width: '100%',
        'align-self': 'center;',
        'justify-content': 'initial',
        'transition': '0.15s padding ease-out',
        '&:hover': {
            'padding-left': 20
        }
    },
    inviteButton: {
        display: 'flex',
    },
    facebook: {
        background: '#3C5A99',
    },
    facebook_ads: {
        background: '#3C5A99',
    },
    google_analytics: {
        background: '#e37400',
    },
    mailchimp: {
        background: '#241C15',
    },
    twitter: {
        background: '#38A1F3',
    },
    instagram: {
        background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    },
});

let api_base = process.env.REACT_APP_API_URL;

class ConnectionForm extends Component {
    state = {
        accountOptions: this.props.connection.options !== undefined ? this.props.connection.options : []
    };

    componentDidMount() {
        fetch(`${api_base}/connections/${this.props.connection.Connection}/options?client=${localStorage.selectedClient}` +
            (this.props.connection.account !== undefined ? `&account=${this.props.connection.account}` : '')
        )
            .then(response => response.json())
            .then(data => this.setState({accountOptions: data.body}));
    }

    updateOptions(args) {
        fetch(`${api_base}/connections/${this.props.connection.Connection}/` +
            `options?client=${localStorage.selectedClient}&` +
            Object.keys(args).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(args[k])}`).join('&')
        )
            .then(response => response.json())
            .then(data => this.setState({accountOptions: data.body}));
    }

    findOptions(account, webproperty, view) {
        let options = [];
        if ((account !== undefined) && (webproperty !== undefined)) {
            this.state.accountOptions.forEach(account_option => {
                if ((account_option.id === account)) {
                    if (account_option.webproperties !== undefined)
                        account_option.webproperties.forEach(webproperty_option => {
                            if (webproperty_option.id === webproperty) {
                                options = webproperty_option.views;
                            }
                        })
                }
            })
        } else if (account !== undefined) {
            this.state.accountOptions.forEach(account_option => {
                if (account_option.id === account) {
                    options = account_option.webproperties;
                }
            })
        }

        if (options === undefined) options = [];

        return options
    }

    findInArrayById(id, array) {
        let result = array.find(item => item.id === id);

        return result !== undefined ? result : {};
    }

    render() {
        let accountOptions = this.state.accountOptions;
        let classes = this.props.classes;
        let initialValues = {
            'name': '',
            'account': '',
            'Client': ''
        };
        if (this.props.connection !== undefined) {
            Object.keys(this.props.connection).map(key => initialValues[key] = this.props.connection[key]);
        }
        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions, el) => {
                        this.props.handleSubmit(JSON.stringify(values, null, 2), initialValues.Connection, this.submitAction === 'values' ? 'values' : '');
                        actions.setSubmitting(false);
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <input type="hidden" name='Client' value={localStorage.selectedClient}/>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Name</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        name="name"
                                    />
                                </FormGroup>
                                {this.props.connection.network === 'facebook' &&
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Account</FormLabel>
                                    <Select
                                        styles={customSelectStyles}
                                        value={props.values.account}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        name='account'
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {accountOptions.map((account) => {
                                            return <MenuItem value={account.id}
                                                             key={account.id}>{account.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormGroup>
                                }
                                {this.props.connection.network === 'google_analytics' &&
                                <div>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Account</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.account,
                                                'label': this.findInArrayById(props.values.account, accountOptions).name
                                            }}
                                            onChange={e => {
                                                this.updateOptions({
                                                    'account': e.value,
                                                    'field': 'webproperty'
                                                });
                                                props.setFieldValue('account', e.value);
                                            }}
                                            name='account'
                                            options={accountOptions.map((account) => {
                                                return {'value': account.id, 'label': account.name}
                                            })}
                                        />
                                    </FormGroup>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Property</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.webproperty,
                                                'label': this.findInArrayById(props.values.webproperty, this.findOptions(props.values.account)).name
                                            }}
                                            onChange={e => {
                                                this.updateOptions({
                                                    'account': props.values.account,
                                                    'webproperty': e.value,
                                                    'field': 'view'
                                                });
                                                props.setFieldValue('webproperty', e.value);
                                            }}
                                            name='webproperty'
                                            options=
                                                {this.findOptions(props.values.account).map((webproperty) => {
                                                    return {'value': webproperty.id, 'label': webproperty.name}
                                                })}
                                        />
                                    </FormGroup>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">View</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.view,
                                                'label': this.findInArrayById(props.values.view, this.findOptions(props.values.account, props.values.webproperty)).name
                                            }}
                                            onChange={e => {
                                                props.setFieldValue('view', e.value);
                                            }}
                                            onBlur={props.handleBlur}
                                            name='view'
                                            options=
                                                {this.findOptions(props.values.account, props.values.webproperty).map((view) => {
                                                    return {'value': view.id, 'label': view.name}
                                                })}
                                        />
                                    </FormGroup>
                                </div>
                                }
                                {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                <FormGroup className={classes.fromGroup}>
                                    {props.isSubmitting ?
                                        <Button disabled type="submit" variant="contained"
                                                color="primary">Save</Button>
                                        :
                                        <Button type="submit" variant="contained"
                                                color="primary">Save</Button>
                                    }
                                    <br/>
                                    <Button type="button" variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this.submitAction = 'values';
                                                props.submitForm();
                                            }}
                                    >Save and configure</Button>
                                </FormGroup>
                            </FormControl>
                        </form>
                    )}
                />
            </div>
        )
    }
}


class Connections extends Component {
    state = {
        sideOpen: false,
        loading: false,
        connectorState: '',
        connections: [],
        connection: {},
        dialogId: '',
        showAlertDialog: false,
        types: [],
        open: false,
        share: this.props.share,
        shareCompany: this.props.shareCompany,
        valuesView: false,
        drawerOpen: false,
        drawerContent: '',
        drawer: {
            name: '',

        }
    };

    componentDidMount() {
        if (this.state.share) {
            localStorage.selectedClient = this.state.shareCompany;
        } else {
            fetch(`${api_base}/connections/?client=${localStorage.selectedClient}`)
                .then(response => response.json())
                .then(data => this.setState({connections: data.body}));
        }
        fetch(`${api_base}/connections/types`)
            .then(response => response.json())
            .then(data => this.setState({types: data.body}));
    }

    /*toggleDrawer = (open) => () => {
        this.setState({
            sideOpen: open,
            valuesView: false
        });
        if (!open) {
            this.setState({connection: {}, loading: false});
        }
    };*/

    setConnection = (connection, content) => {
        this.setState({
            connection: connection,
            drawerOpen: true,
            drawerContent: content,
            drawer: {'name': 'Edit Connection'}
        })
    };

    checkConnectionState = (network) => {
        fetch(`${api_base}/connections/check/${network}/${this.state.connectorState}?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                if ((data.body.length > 0) && (data.body[0].access_token !== undefined)) {
                    this.setState({
                        connectorState: '',
                        loading: false,
                        connection: data.body[0],
                        drawerContent:
                            <div className={this.props.classes.drawerContainer}>
                                <ConnectionForm connection={data.body[0]} classes={this.props.classes}
                                                handleSubmit={this.updateConnection}
                                                setValues={this.setValuesView}/>
                            </div>
                    });

                    fetch(`${api_base}/connections/?client=${localStorage.selectedClient}`)
                        .then(response => response.json())
                        .then(data => this.setState({connections: data.body}));
                    clearTimeout();
                } else {
                    setTimeout(() =>
                            this.checkConnectionState(network),
                        1500)
                }
            });
    };

    connectSource = (network) => {
        fetch(`${api_base}/connections/connect/${network}?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                if (data.redirect === true) {
                    window.open(data.url);
                }
                this.setState({connectorState: data.state, loading: true});
                this.checkConnectionState(network);
            });

    };

    handleAlertDialog = (id) => {
        this.setState({showAlertDialog: !this.state.showAlertDialog, dialogId: id})
    };

    removeConnectionById = () => {
        fetch(`${api_base}/connections/${this.state.dialogId}?client=${localStorage.selectedClient}`, {
            method: 'DELETE',
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/connections/?client=${localStorage.selectedClient}`)
                    .then(response => response.json())
                    .then(data => this.setState({connections: data.body, showAlertDialog: false}));
            }
        )
    };

    updateConnection = (data, id, values) => {
        fetch(`${api_base}/connections/${id}?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        }).then(
            response => {
                fetch(`${api_base}/connections/?client=${localStorage.selectedClient}`)
                    .then(response => response.json())
                    .then(data => this.setState({
                        connections: data.body,
                        drawerOpen: values === "values",
                        drawerContent: values === "values" &&
                            <div className={this.props.classes.drawerContainer}>
                                <Values
                                    full
                                    setDrawerContent={this.setDrawerContent.bind(this)}
                                    onDrawerSubmit={this.onDrawerSubmit.bind(this)}
                                    classes={this.props.classes}
                                />
                            </div>,
                        connection: {}
                    }));
            }
        )
    };

    handleInviteOpen = () => {
        this.setState({open: true});
    };

    handleInviteClose = () => {
        this.setState({open: false});
    };

    toggleDrawer = (open) => () => {
        this.setState({
            drawerOpen: open
        });
    };

    setDrawerContent(content) {
        this.setState({
            drawerOpen: true,
            drawerContent: content
        });
    };

    onDrawerSubmit(content) {
        this.setState({
            drawerOpen: true,
            drawerContent:  <div className={this.props.classes.drawerContainer}>
                <Values full setDrawerContent={this.setDrawerContent.bind(this)} onDrawerSubmit={this.onDrawerSubmit.bind(this)}/>
            </div>
        });
    };

    addConnection(content) {
        this.setState({
            drawerOpen: true,
            drawer: {'name': 'Add Connection'},
            drawerContent: content
        })
    };

    render() {
        const {classes} = this.props;
        let connection = this.state.connection;

        return (
            <React.Fragment>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    {/*Header Component*/}
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5" style={{marginRight: '15px'}}>
                                Connections
                            </Typography>
                            <div style={{flexGrow: 1, textAlign: 'right'}}>
                                <AddButton
                                    name='Add connection'
                                    onClick={this.addConnection
                                        .bind(
                                            this,
                                            <div className={classes.drawerContainer}>
                                                {this.state.types.map(type =>
                                                    <div key={type.key} className={classes.drawerContainerWrapper}>
                                                        <Button
                                                            className={classes.connectionButton}
                                                            onClick={() => this.connectSource(type.key)}
                                                        >
                                                            <Avatar
                                                                className={[classes['avatar'], classes[type.key]].join(' ')}>
                                                                <SocialIcon name={type.key} width={100} fill={'#fff'}/>
                                                            </Avatar>
                                                            {type.name}
                                                        </Button>
                                                        <Button
                                                            className={classes.inviteButton}
                                                            onClick={this.handleInviteOpen}>
                                                            <Share/>
                                                        </Button>
                                                        <br/>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Grid>
                    {this.state.connections.map((connection) =>
                        <Grid item md={4} sm={6} xs={12} key={connection.Connection}>
                            <Connector connection={connection}
                                       showDialog={this.handleAlertDialog.bind(this, connection.Connection)}
                                       onEdit={this.setConnection.bind(
                                           this,
                                           connection,
                                           <div className={classes.drawerContainer}>
                                               <ConnectionForm connection={connection} classes={this.props.classes}
                                                               handleSubmit={this.updateConnection}
                                                               setValues={this.setValuesView}/>
                                           </div>
                                       )}
                            />
                        </Grid>
                    )}
                </Grid>

                <DefaultDrawer
                    open={this.state.drawerOpen}
                    name={this.state.drawer.name}
                    onClose={this.toggleDrawer(false)}
                    content={this.state.drawerContent}
                />

                {/*<DefaultDrawer
                    open={this.state.sideOpen}
                    name={Object.keys(connection).length ? 'Edit connection' : 'Add connection'}
                    onClose={this.toggleDrawer(false)}
                    content={
                        this.state.valuesView ? <div className={classes.drawerContainer}><Values full/></div> :
                            this.state.loading ?
                                <div className={classes.drawerContainer}>
                                    <div className={classes.loadingContainer}>
                                        <CircularProgress/>
                                    </div>
                                </div>
                                :
                                Object.keys(connection).length ?
                                    <div className={classes.drawerContainer}>
                                        <ConnectionForm connection={connection} classes={this.props.classes}
                                                        handleSubmit={this.updateConnection}
                                                        setValues={this.setValuesView}/>
                                    </div>
                                    :
                                    <div className={classes.drawerContainer}>
                                        {this.state.types.map(type =>
                                            <div key={type.key} className={classes.drawerContainerWrapper}>
                                                <Button
                                                    className={classes.connectionButton}
                                                    onClick={() => this.connectSource(type.key)}
                                                >
                                                    <Avatar
                                                        className={[classes['avatar'], classes[type.key]].join(' ')}>
                                                        <SocialIcon name={type.key} width={100} fill={'#fff'}/>
                                                    </Avatar>
                                                    {type.name}
                                                </Button>
                                                <Button
                                                    className={classes.inviteButton}
                                                    onClick={this.handleInviteOpen}>
                                                    <Share/>
                                                </Button>
                                                <br/>
                                            </div>
                                        )}
                                    </div>
                    }
                />*/}

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Invite</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the email address that will recieve the invitation
                            <br/>
                            or go/copy this link - <a
                            href={window.location.origin + '/share/connection/' + localStorage.selectedClient}>{window.location.origin}/share/connection/{localStorage.selectedClient}</a>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleInviteClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleInviteClose} color="primary">
                            Invite
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showAlertDialog}
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm deleting the connection"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            When Deleting a connection, all the data will be lost. <br/>
                            Are you sure you want to
                            proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertDialog} color="primary" autoFocus>
                            Discard
                        </Button>
                        <Button onClick={this.removeConnectionById} color="primary">
                            Yes, delete it
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

//export default Connections;
export default withStyles(styles)(Connections);
