import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Dashboard from "../DashboardSingle";
import './Share.css';
import ShareConnectionScene from "./Connection";

class ShareScene extends Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route path="/share/connection/:cmp" exact component={ShareConnectionScene}/>
                        <Route path="/share/:dashboard/:cmp" exact component={Dashboard}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default ShareScene;