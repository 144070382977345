import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import {Link} from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import './CompanyCard.css';
//import {NavLink} from "react-router-dom";

const styles = theme => (
    {
        actions: {
            justifyContent: 'space-evenly'
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        card: {
            minHeight: 150
        }
    }
);

function CompanyCard(props) {
    const {classes, client, showDialog, setClientId} = props;
    //const hasNestedClients = typeof client.clients !== "undefined";
    return (
        <Card className={classes.card}>
            <Tooltip title="Client users">
                <CardActionArea component={Link} to={`/clients/${client.id}`} aria-label="Client users">
                    <CardContent style={{height: '100px', overflow: 'hidden'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{paddingRight: '10px'}}>
                                <Avatar src={client.logo}>
                                    {client.name.substr(0, 2).toLocaleUpperCase()}
                                </Avatar>
                            </div>
                            <div style={{flex: 1}}>
                                <Typography variant="h5" component="h2">
                                    {client.name}
                                </Typography>
                                {client.description ?
                                    <Typography variant="body1">
                                        {client.description}
                                    </Typography> : null}
                            </div>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Tooltip>
            <CardActions className={classes.actions} disableActionSpacing>
                <IconButton aria-label="Edit client" onClick={() => {setClientId(client.id)}}>
                    <Tooltip title="Edit client">
                        <EditIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton aria-label="Delete client" onClick={showDialog}>
                    <Tooltip title="Delete client">
                        <DeleteIcon/>
                    </Tooltip>
                </IconButton>
            </CardActions>
        </Card>
    );
}

CompanyCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompanyCard);