import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import {Auth} from 'aws-amplify';
import Amplify from 'aws-amplify';
import congnito from '../../../aws-congnito';
import GeneralIcon from "../../Icons/GeneralIcons";
import MenuListButton from "../../MenuListButton";
import { Link } from 'react-router-dom'

const styles = theme => ({
    topRightNav:{
        display: 'flex',
        'align-self': 'stretch',
    },
    selectTopBar: {
        color: '#ffffff'
    },
    accountText: {
        color: '#fff',
        textTransform: 'none',
        fontSize: 16,
        paddingLeft: 5,
        'font-family': 'IBM Plex Sans, sans-serif',
        fontWeight: 500,
    },
    darkTopNav: {
        background: theme.palette.primary.main,
        display: 'inline-flex',
    },
    darkLightBtn: {
        background: theme.palette.lightDark.main,
        'border-radius': '0',
        width: '90px',
        '&:hover': {
            background: theme.palette.lightDark.active,
        }
    },
    topNavBtn: {
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 500,
        'font-family': 'IBM Plex Sans, sans-serif',
        fontSize: 14
    },
    topNavDarkBtn: {
        paddingLeft: 25,
        paddingRight: 25,
        'border-radius': '0',
        '&:hover': {
            background: theme.palette.primary.active,
        }
    }
});

Amplify.configure(congnito);
let user = {};

let api_base = process.env.REACT_APP_API_URL;

class TopRightNav extends Component {
    state = {
        auth: true,
        anchorEl: null,
        selectedClient: localStorage.getItem('selectedClient'),
        user: user
    };

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(res => user = res);

        Auth.currentSession()
            .then(session => {
                    fetch(`${api_base}/profile/?access_token=${session.accessToken.jwtToken}`)
                        .then(response => response.json())
                        .then(data => {
                                //
                                this.setState({user: data.body});
                            }
                        );
                }
            )
            .catch(err => console.log(err));
    }

    handleChange = event => {
        this.setState({auth: event.target.checked});
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {classes} = this.props;
        //const {anchorEl} = this.state;
        //const open = Boolean(anchorEl);
        const user = this.state.user;
        return (
             <div className={classes.topRightNav} style = {{ zIndex: 1 }} >
                <MenuListButton 
                    classNameWrapper = { classes.topRightNav }
                    classNameButton = { classes.topNavBtn }
                    icon = { < GeneralIcon name = 'group' width={ 28 } style={{ marginRight: 10 }} height={ 23 } fill = { '#1f1f1f' }/>}
                    text = 'Clients'
                    items = {
                        this.props.clients.map(item => 
                            <MenuItem
                            selected= {this.state.selectedClient === item.id}
                            onClick = {(event) => this.props.onClientChange(item.id)}
                            value={item.id} key={item.id}>{item.name}</MenuItem>
                            )
                        }/>
                <div className={classes.darkTopNav}>
                <MenuListButton 
                    classNameWrapper = { classes.darkTopNav }
                    classNameButton = { classes.topNavDarkBtn }
                    icon = { <GeneralIcon name='account' width={ 23 } style={{ marginRight: 5 }} height={ 23 } fill={'#ffffff'}/>}
                    text = {(Object.keys(user).length > 0) ?
                    <span className={classes.accountText}>{user.name !== undefined ? user.name : user.email}</span>
                    : null}
                    items = {[
                            <MenuItem key={'logout'} onClick={() => {
                                Auth.signOut()
                                    .then(data => window.location.replace('/'))
                                    .catch(err => console.log(err));
                            }}>Logout</MenuItem>,
                            <MenuItem key={'usersettings'} selected={window.location.pathname === '/usersettings'} component={Link} to="/usersettings">
                            User Settings
                            </MenuItem>
                        ]}/>
                    <IconButton color="primary" className={classes.darkLightBtn} >
                        <Badge badgeContent={0} color="secondary">
                            <GeneralIcon name='mail' height={19} width={23} fill={'#ffffff'}/>
                        </Badge>
                    </IconButton>
                </div>
            </div>
        );
    }
}

//export default TopRightNav;
export default withStyles(styles)(TopRightNav);