import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Table from '../../../components/Table';

//Styles:
import '../../../styles/dashboard.css';
import IconButton from "@material-ui/core/IconButton";
import AppsIcon from "@material-ui/icons/Apps";
import DehazeIcon from "@material-ui/icons/Dehaze";
import AddIcon from "@material-ui/icons/Add";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        paddingLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerContainer: {
        padding: 20
    },
    formGroup: {
        padding: 10
    },
    actions: {
        justifyContent: 'space-evenly'
    },
});

let api_base = process.env.REACT_APP_API_URL;

class CostForm extends Component {
    state = {
        columns: [
            {title: 'Name', field: 'name'},
            {title: 'Cost', field: 'cost', type: 'numeric'},
            {
                title: 'Type',
                field: 'type',
                lookup: {
                    'onetime': 'One-time',
                    'recurring': 'Recurring'
                }
            },
            {
                title: 'Start date',
                field: 'start_date',
                type: 'date',
                render: (rowData) => {
                    if (rowData.start_date === undefined) return <span></span>;
                    let date = rowData.start_date;
                    if (typeof rowData.start_date === 'string') date = new Date(rowData.start_date);
                    return <span>{date.toLocaleDateString('en-GB')}</span>;
                }
            },
            {
                title: 'End date',
                field: 'end_date',
                type: 'date',
                render: (rowData) => {
                    if (rowData.end_date === undefined) return <span></span>;
                    let date = rowData.end_date;
                    if (typeof rowData.end_date === 'string') date = new Date(rowData.end_date);
                    return <span>{date.toLocaleDateString('en-GB')}</span>;
                }
            },
        ]
    };

    updateOptions(args) {

    }

    submitForm(values) {
        if (Object.keys(this.props.cost).length > 0) {
            this.props.onUpdate(values, this.props.cost);
        } else {
            this.props.onAdd(values);
        }
    }

    findInArrayById(id, array) {
        let result = array.find(item => item.id === id);

        return result !== undefined ? result : {};
    }

    render() {
        const {classes} = this.props;
        let initialValues = {
            'cost': ' ',
            'name': ''
        };

        if (Object.keys(this.props.cost).length > 0) {
            Object.keys(this.props.cost).map(key => initialValues[key] = this.props.cost[key]);
        }

        return (
            <div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, actions, el) => {
                        this.submitForm(values);
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <input type="hidden" name='Client' value={localStorage.selectedClient}/>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <input type="hidden" name='Client' value={localStorage.selectedClient}/>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Name</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        name="name"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    <FormLabel component="legend">Cost</FormLabel>
                                    <TextField
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.cost}
                                        name="cost"
                                    />
                                </FormGroup>
                                <FormGroup className={classes.formGroup}>
                                    {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                    <Button type="submit" variant="contained"
                                            color="primary">
                                        {Object.keys(this.props.cost).length > 0 ? 'Save' : 'Add'}
                                    </Button>
                                </FormGroup>
                            </FormControl>

                        </form>
                    )}
                />
            </div>
        )
    }
}

class Costs extends Component {
    state = {
        showAlertDialog: false,
        sideOpen: false,
        columns: [
            {title: 'Name', field: 'name'},
            {title: 'Cost', field: 'cost', type: 'numeric'},
            {
                title: 'Type',
                field: 'type',
                lookup: {
                    'onetime': 'One-time',
                    'recurring': 'Recurring'
                }
            },
            {
                title: 'Start date',
                field: 'start_date',
                type: 'date',
                render: (rowData) => {
                    if (rowData.start_date === undefined) return <span></span>;
                    let date = rowData.start_date;
                    if (typeof rowData.start_date === 'string') date = new Date(rowData.start_date);
                    return <span>{date.toLocaleDateString('en-GB')}</span>;
                }
            },
            {
                title: 'End date',
                field: 'end_date',
                type: 'date',
                render: (rowData) => {
                    if (rowData.end_date === undefined) return <span></span>;
                    let date = rowData.end_date;
                    if (typeof rowData.end_date === 'string') date = new Date(rowData.end_date);
                    return <span>{date.toLocaleDateString('en-GB')}</span>;
                }
            },
        ],
        data: [],
        currentCost: {},
        valueView: 'cards'
    };

    componentDidMount() {
        fetch(`${api_base}/settings/costs?client=${localStorage.selectedClient}`)
            .then(response => response.json())
            .then(data => {
                    this.setState({data: data.body});
                }
            );
    }

    onAdd(row) {
        let data = this.state.data;
        row['key'] = '' + (data.length + 1);
        data.push(row);
        this.setState({data: data, valueView: this.state.valueView === 'cards' ? 'cards' : 'table'});
        this.props.onDrawerSubmit();

        fetch(`${api_base}/settings/costs?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
    }

    onUpdate(row, oldrow) {
        let data = this.state.data;
        data[data.indexOf(oldrow)] = row;
        this.props.onDrawerSubmit();
        this.setState({data: data, valueView: this.state.valueView === 'cards' ? 'cards' : 'table'});
        fetch(`${api_base}/settings/costs?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
    }

    onDelete(row) {
        let data = this.state.data;
        data.splice(data.indexOf(row), 1);
        this.setState({data: data});
        fetch(`${api_base}/settings/costs?client=${localStorage.selectedClient}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        })
    }


    setValueView(view) {
        this.setState({valueView: view});
    }

    setCurrentCost(cost) {
        this.setState({currentCost: cost});
        this.props.setDrawerContent(
            <CostForm cost={cost}
                      classes={this.props.classes}
                      onAdd={this.onAdd.bind(this)}
                      onUpdate={this.onUpdate.bind(this)}
            />
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6">
                                Costs
                            </Typography>
                            <IconButton onClick={this.setValueView.bind(this, 'cards')}>
                                <AppsIcon/>
                            </IconButton>
                            <IconButton onClick={this.setValueView.bind(this, 'table')}>
                                <DehazeIcon/>
                            </IconButton>
                            <IconButton onClick={this.setCurrentCost.bind(this, {})}>
                                <AddIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                    {this.state.valueView === 'cards' &&
                    this.state.data.map(item => {
                        return (
                            <Grid item md={this.props.full ? 12 : 4} sm={this.props.full ? 12 : 6} xs={12}
                                  key={item.id}>
                                <Card className={styles.paper} style={{padding: '10px'}}>
                                    <CardContent onClick={this.setCurrentCost.bind(this, item)}>
                                        <div style={{display: 'flex'}}>
                                            <div style={{paddingRight: '10px'}}>
                                                <Avatar>
                                                    <AttachMoneyIcon/>
                                                </Avatar>
                                            </div>
                                            <div style={{flex: 1}}>
                                                <Typography variant="h5" component="h2">
                                                    {item.name} {item.cost && ` - ${item.cost} SEK`}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {item.type} {item.start}
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <CardActions className={classes.actions} disableActionSpacing>
                                        <IconButton onClick={this.setCurrentCost.bind(this, item)}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton onClick={this.onDelete.bind(this, item)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })
                    }
                    {this.state.valueView === 'table' &&
                    <Table
                        columns={this.state.columns}
                        data={this.state.data}
                        onAdd={this.onAdd.bind(this)}
                        onUpdate={this.onUpdate.bind(this)}
                        onDelete={this.onDelete.bind(this)}
                        grouping={true}
                    />
                    }

                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Costs);