import React from 'react';
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import {withStyles} from "@material-ui/core/styles";
import SvgIcon from '@material-ui/core/SvgIcon';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
    input: {
        paddingRight: '8px',
        paddingLeft: '8px'
    },
    facebookAvatar: {
        background: '#3C5A99'
    },
    google_analyticsAvatar: {
        background: '#FFC517'
    },
    actions: {
        justifyContent: 'space-evenly'
    },
});

const socialIcon = (network) => {
    if (network === 'facebook') return <SvgIcon>
        <path
            d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2		C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"></path>
    </SvgIcon>
    if (network === 'google_analytics') return <SvgIcon width={'20px'} height={'20px'} fill={'#ffffff'} x={0} y={0}
                                                        viewBox={"0 0 101.968 101.968"}>
        <g>
            <path d="M24.715,47.432L7.968,64.86v29.406c0,0.828,0.671,1.5,1.5,1.5h20.334c0.828,0,1.5-0.672,1.5-1.5V49.158l-4.69-1.726
			H24.715z"/>
            <path d="M66.135,61.1H45.801c-0.828,0-1.5,0.672-1.5,1.5v31.666c0,0.828,0.672,1.5,1.5,1.5h20.334c0.829,0,1.5-0.672,1.5-1.5V62.6
			C67.635,61.772,66.964,61.1,66.135,61.1z"/>
            <path d="M101.724,29.49c-0.777,0.406-1.652,0.621-2.53,0.621c-1.276,0-2.521-0.45-3.5-1.27l-3.694-3.088l-13.365,14.58v53.934
			c0,0.828,0.672,1.5,1.5,1.5h20.334c0.829,0,1.5-0.672,1.5-1.5v-64.93C101.885,29.387,101.81,29.445,101.724,29.49z"/>
            <path d="M57.797,54.094c1.144,0.419,2.424,0.108,3.248-0.788l30.839-33.643l7.217,6.032c0.353,0.294,0.847,0.349,1.254,0.136
			c0.407-0.214,0.646-0.648,0.605-1.107L99.396,7.235c-0.055-0.625-0.606-1.086-1.231-1.029l-17.49,1.563
			c-0.458,0.041-0.846,0.354-0.982,0.791C79.646,8.706,79.631,8.854,79.644,9c0.026,0.294,0.167,0.572,0.403,0.769l7.229,6.043
			L57.98,47.769L24.535,35.463c-1.118-0.41-2.373-0.121-3.198,0.735l-20.5,21.333c-1.148,1.195-1.11,3.095,0.084,4.242
			c0.583,0.561,1.332,0.837,2.079,0.837c0.788,0,1.574-0.309,2.164-0.921l19.141-19.92L57.797,54.094z"/>
        </g>
    </SvgIcon>
};

const Connector = (props) => {
    const {classes, showDialog, connection, onEdit} = props;
    const account = connection.options && connection.options.find(account => account.id === connection.account);
    return (
        <Card className={styles.paper} style={{padding: '10px'}}>
            <CardContent>
                <div style={{display: 'flex'}}>
                    <div style={{paddingRight: '10px'}}>
                        <Avatar className={classes[connection.network + 'Avatar']}>
                            {socialIcon(connection.network)}
                        </Avatar>
                    </div>
                    <div style={{flex: 1}}>
                        <Typography variant="h5" component="h2">
                            {account && account.name}
                        </Typography>
                        <Typography variant="body1">
                            {connection && connection.name}
                        </Typography>
                    </div>
                </div>
            </CardContent>
            <CardActions className={classes.actions} disableActionSpacing>
                {connection.disconnected ?
                    <IconButton>
                        <ReplayIcon color="error"/>
                    </IconButton> : null
                }
                <IconButton onClick={onEdit}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={showDialog}>
                    <DeleteIcon/>
                </IconButton>
            </CardActions>
        </Card>
    );
};

Connector.propTypes = {};
Connector.defaultProps = {};

export default withStyles(styles)(Connector);
