import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import Button from "@material-ui/core/Button";
import {Formik} from "formik";

const customSelectStyles = {
    control: () => ({
        display: "flex",
        alignItems: "center",
        border: 0,
        height: "auto",
        background: "transparent",
        position: "relative",
        "&:hover": {
            boxShadow: "none",
        },
        "&:after": {
            content: "''",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:after": {
            borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
        },
    }),
    menu: () => ({
        backgroundColor: "white",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        position: "absolute",
        left: 0,
        top: "calc(100% + 1px)",
        width: "100%",
        zIndex: 200,
        maxHeight: 200
    }),
    valueContainer: () => ({
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "6px 0 7px",
    }),
    menuList: () => ({
        maxHeight: 200,
        overflowY: "auto",
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    singleValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
    }),
    multiValue: () => ({
        fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont," +
            "Lato, Roboto,'Helvetica Neue',Arial,sans-serif",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1rem",
        lineHeight: "1.1875em",
        backgroundColor: "hsl(0,0%,90%)",
        boxSizing: "border-box",
        display: "flex",
        borderRadius: "2px",
    })
};

const styles = theme => ({
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    formGroup: {
        padding: 10
    },
    formControl: {
        width: '100%'
    },
    actions: {
        justifyContent: 'space-evenly'
    },
});

class WidgetConfiguration extends React.Component {
    state = {
        types: [
            {'id': 'numeric', 'name': 'Numeric'},
            {'id': 'line', 'name': 'Line'},
            {'id': 'bar', 'name': 'Bar'},
            {'id': 'value', 'name': 'Value'}
        ],
        sizes: [
            {'id': 3, 'name': 'Small'},
            {'id': 6, 'name': 'Wide'},
            {'id': 12, 'name': 'Full'}
        ],
        options: this.props.values,
        widget: this.props.widget,
        labels_map: this.props.widget.labels !== undefined ? this.props.widget.labels.split(',') : []
    };

    findInArrayById(id, array) {
        let result = array.find(item => item.id === id);

        return result !== undefined ? result : {};
    }

    filterOptions(inputValue) {
        console.log(inputValue)
        console.log(this.state.options)
        return this.state.options.filter(i =>
            i.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    promiseOptions(inputValue) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterOptions(inputValue).map(item => {
                    return {'value': item, 'label': item}
                }));
            }, 1000);
        });
    }

    handleLabel(e) {
        let labels = this.state.labels_map;
        labels[e.target.name.split('_')[1]] = e.target.value;
        this.setState({labels_map: labels});
        let widget = this.state.widget;
        widget['labels'] = labels.join(',');
        this.setState({widget: widget});
    }

    render() {
        const {classes} = this.props;
        const dashboard = this.props.dashboard;
        const onClose = this.props.onClose;
        const content = this.props.content;
        const widget = this.state.widget;
        const triggers = this.props.values;
        const index = this.props.index;
        widget['size'] = widget.size !== undefined ? widget.size : 3;
        widget['type'] = widget.type !== undefined ? widget.type : 'numeric';
        return (
            <Grid container spacing={16} style={{margin: 0, width: 300,}}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={widget}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            this.props.onSaveWidget(values, index);
                        }}
                        render={props => (
                            <form onSubmit={props.handleSubmit}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Name</FormLabel>
                                        <TextField
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                            name="name"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Description</FormLabel>
                                        <TextField
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.description}
                                            name="description"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Type</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.type,
                                                'label': this.findInArrayById(props.values.type, this.state.types).name
                                            }}
                                            onChange={e => {
                                                props.setFieldValue('type', e.value);
                                            }}
                                            name='type'
                                            options={this.state.types.map((item) => {
                                                return {'value': item.id, 'label': item.name}
                                            })}
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Size</FormLabel>
                                        <Select
                                            styles={customSelectStyles}
                                            value={{
                                                'value': props.values.size,
                                                'label': this.findInArrayById(props.values.size, this.state.sizes).name
                                            }}
                                            onChange={e => {
                                                props.setFieldValue('size', e.value);
                                            }}
                                            name='size'
                                            options={this.state.sizes.map((item) => {
                                                return {'value': item.id, 'label': item.name}
                                            })}
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Value</FormLabel>
                                        <AsyncSelect
                                            styles={customSelectStyles}
                                            value={props.values.value && props.values.value.map(value => {
                                                return {'value': value, 'label': value.substr(0, 30) + '..'}
                                            })}
                                            onChange={e => {
                                                props.setFieldValue('value', e ? e.map(item => item.value) : []);
                                            }}
                                            onBlur={props.handleBlur}
                                            name='value'
                                            isMulti
                                            loadOptions={this.promiseOptions.bind(this)}
                                            options={triggers.map(item => {
                                                return {'value': item, 'label': item.substr(0, 30) + '..'}
                                            })}
                                        >
                                        </AsyncSelect>
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Labels</FormLabel>
                                        {props.values.value && props.values.value.map((value, index) =>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type="text"
                                                        disabled
                                                        value={value}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type="text"
                                                        value={typeof this.state.labels_map[index] !== 'undefined' ? this.state.labels_map[index] : ''}
                                                        name={'label_' + index}
                                                        onChange={this.handleLabel.bind(this)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <TextField
                                            type="hidden"
                                            value={props.values.labels}
                                            name="labels"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup className={classes.formGroup}>
                                        <FormLabel component="legend">Units</FormLabel>
                                        <TextField
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.units}
                                            name="units"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormGroup className={classes.fromGroup}>
                                    <Button disabled={props.isSubmitting ? true : false} type="submit"
                                            variant="contained"
                                            color="primary">Save</Button>
                                </FormGroup>
                            </form>
                        )}
                    />

                </Grid>
            </Grid>
        );
    }
}

WidgetConfiguration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WidgetConfiguration);
