import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import {
    Area,
    ComposedChart,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis
} from "recharts";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import copy from "clipboard-copy";
import LinkIcon from "@material-ui/icons/Link";

const styles = theme => ({
    editPenIcon: {
        float: 'right',
        right: 0,
        position: 'relative',
        top: 0,
    }
});

const formatBigNumbers = n => {
    if (n < 1e6) return n;
    //if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12 && n < 1e15) return +(n / 1e12).toFixed(1) + "T";
    if (n >= 1e15 && n < 1e18) return +(n / 1e15).toFixed(1) + "P";
    if (n >= 1e18) return +(n / 1e18).toFixed(1) + "E";
};


const sortOnKeys = (dict) => {
    let sorted = [];
    for (let key in dict) {
        sorted[sorted.length] = key;
    }
    sorted.sort();

    let tempDict = {};
    for (let i = 0; i < sorted.length; i++) {
        tempDict[sorted[i]] = dict[sorted[i]];
    }

    return tempDict;
};

const checkAndFormatDate = (date) => {
    let dateObject = typeof date === "object" ? date : new Date(date);
    if (dateObject >= new Date()) return false;

    return `${dateObject.toISOString().substring(0, 10)}`;
};

const formatDate = (date) => {
    let dateObject = typeof date === "object" ? date : new Date(date);
    return `${dateObject.toISOString().substring(0, 10)}`;
};

const formatTodayDate = () => {
    let dateObject = new Date();

    return `${dateObject.toISOString().substring(0, 10)}`;
};
const formatEndDate = () => {
    let dateObject = new Date();
    dateObject.setDate(dateObject.getDate() - 30);

    return `${dateObject.toISOString().substring(0, 10)}`;
};

const COLORS = ['#ffde28', '#000000', '#ce3ac3'];

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload) {
        return (
            <div className="tooltip" style={{borderColor: payload[0].color}}>
                {payload.map(item => {
                    // if (item.value <= 0) return null;
                    return <p key={item.name} className="indicator">
                                <span className="number">
                                    {`${formatBigNumbers(item.value)}`}
                                </span>
                        <span className="label">
                                    {item.name}
                                </span>
                    </p>
                })}
                <div className="date">
                    {payload[0].payload.name}
                </div>
            </div>
        );
    }

    return null;
};

const ReferenceLabel = (props) => {
    const {
        fill, name, textAnchor,
        fontSize, viewBox, dy, dx,
    } = props;
    const x = viewBox.width + viewBox.x + 20;
    const y = viewBox.y + 50;
    return (
        <text
            x={x} y={y}
            dy={dy}
            dx={dx}
            fill={fill}
            fontSize={fontSize || 16}
            textAnchor={textAnchor}>
            {name}
        </text>
    )
}

const PopoverPopupState = (props) => {

    return (
        <PopupState variant="popover">
            {popupState => (
                <div style={{marginLeft: '5px'}}>
                    <Button className={props.className}
                            color="primary"
                            variant="outlined"
                            {...bindTrigger(popupState)}
                    >
                        Share
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography style={{padding: 10}}>
                            <IconButton
                                onClick={() => copy(`${document.location.origin}/share/${props.did}/${props.company}`)}>
                                <LinkIcon/>
                            </IconButton>
                            {document.location.origin}/share/{props.did}/{props.company}
                        </Typography>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}

class ValueWidget extends React.Component {
    state = {};

    getLineChartData(value) {
        let data = {};

        Object.keys(value).forEach(key => {
            Object.keys(value[key]).forEach(day => {
                if (data[day] === undefined) data[day] = {'name': day};
                data[day][key] = value[key][day];
            });
        });

        return Object.values(data)
    }

    render() {
        const {classes} = this.props;
        const dashboard = this.props.dashboard;
        const onClose = this.props.onClose;
        const content = this.props.content;
        const widget = this.props.widget;

        let data = [];
        if (this.props.data.all_daily) {
            data = Object.values(this.props.data.all_daily);
        }
        const gradientOffset = () => {
            const dataMax = Math.max(...data.map(i => i.roas));
            const dataMin = Math.min(...data.map(i => i.roas));

            if (dataMax <= 0) {
                return 0;
            }
            if (dataMin >= 0) {
                return 1;
            }

            return dataMax / (dataMax - dataMin);
        };
        const off = gradientOffset();

        return (
            <Paper style={{minHeight: '440px', textAlign: 'center', alignItems: 'center'}}
                   elevation={1}>
                <IconButton className={'Dashboard-editPenIcon ' + classes.editPenIcon}>
                    <EditIcon onClick={this.props.handleEditWidget.bind(this, widget, this.props.index)}/>
                </IconButton>
                <div className="widget-hd">
                    {widget.name}
                </div>
                <ResponsiveContainer width="99%" height={400}>
                    <ComposedChart
                        data={this.props.data.all_daily && Object.values(this.props.data.all_daily)}
                        margin={{top: 5, right: 30, left: 20, bottom: 5}}
                    >
                        <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                        {this.state.costs && this.state.costs.map(
                            cost => <ReferenceLine
                                className="reference-label"
                                key={cost.name}
                                x={formatDate(cost.start_date)}
                                label={<ReferenceLabel {...cost} />}
                                stroke="rgba(0,0,0,0.5)"
                                strokeWidth={2}
                                strokeDasharray="20 20"
                            />
                        )}
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend iconType="circle" layout="horizontal" verticalAlign="top"
                                align="center"/>
                        <defs>
                            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset={off} stopColor="rgba(0,128,0,0.8)" stopOpacity={1}/>
                                <stop offset={off} stopColor="rgba(255,0,0,0.8)" stopOpacity={1}/>
                            </linearGradient>
                        </defs>
                        <Area type="monotone" dataKey="roas"
                              name="Return on spend"
                              stroke="url(#splitColor)"
                              fill="url(#splitColor)"
                              strokeWidth={3}
                              activeDot={{r: 10}}/>
                        <Line type="monotone" dataKey="value"
                              name="Value" stroke={COLORS[0]}
                              strokeWidth={3}
                              activeDot={{r: 10}}/>
                    </ComposedChart>

                </ResponsiveContainer>
            </Paper>
        );
    }
}

ValueWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ValueWidget);
