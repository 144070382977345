import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Auth} from 'aws-amplify';

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 700,
    },
    formGroup: {
        padding: 10
    },
});

let api_base = process.env.REACT_APP_API_URL;

class UserSettings extends Component {
    state = {
        open: false,
        user: {
            'name': '',
            'email': '',
            'phone_number': ''
        },
        token: ''
    };

    componentDidMount() {
        Auth.currentSession()
            .then(session => {
                    fetch(`${api_base}/profile/?access_token=${session.accessToken.jwtToken}`)
                        .then(response => response.json())
                        .then(data => {
                                //
                                this.setState({user: data.body, token: session.accessToken.jwtToken});
                            }
                        );
                }
            )
            .catch(err => console.log(err));
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
    }

    updateUserSettings(values) {
        fetch(`${api_base}/profile/?access_token=${this.state.token}`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow"
        });
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={16} style={{margin: 0, width: '100%',}}>
                    {/*UserSettings Component*/}
                    <Grid item xs={12}>
                        <div className="dashboard-hd" style={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h5" style={{marginRight: '15px'}}>
                                User settings
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={styles.root}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={this.state.user}
                                onSubmit={(values, actions) => {
                                    setTimeout(() => {
                                        this.updateUserSettings(values);
                                        actions.setSubmitting(false);
                                    }, 1000);
                                }}
                                render={props => (
                                    <form onSubmit={props.handleSubmit}>
                                        <FormControl component="fieldset">
                                            <input type="hidden" name='id' value={props.values.Username}/>
                                            <input type="hidden" name='Client' value={localStorage.selectedClient}/>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <FormGroup className={classes.formGroup}>
                                                        <FormLabel component="legend">Name</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.name}
                                                            name="name"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup className={classes.formGroup}>
                                                        <FormLabel component="legend">Email</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.email}
                                                            name="email"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormGroup className={classes.formGroup}>
                                                        <FormLabel component="legend">Phone number</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.phone_number}
                                                            name="phone_number"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormGroup className={classes.formGroup}>
                                                        <FormLabel component="legend">Change password</FormLabel>
                                                        <TextField
                                                            type="text"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.password_change}
                                                            name="password_change"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                                    <FormGroup className={classes.formGroup}>
                                                        {props.isSubmitting ?
                                                            <Button disabled type="submit" variant="contained"
                                                                    color="primary">Update information</Button>
                                                            :
                                                            <Button type="submit" variant="contained"
                                                                    color="primary">Update information</Button>
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </form>
                                )}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

UserSettings.propTypes = {};

export default withStyles(styles)(UserSettings);