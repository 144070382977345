// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const congnito = {
    'Auth': {
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_yAbviQuLb',
        userPoolWebClientId: '4tleaqe392ml2juddadf1ju9ms'
    }
};


export default congnito;
