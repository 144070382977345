import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import NumericWidget from "../Widget/Numeric";
import LineChartWidget from "../Widget/LineChart";
import BarChartWidget from "../Widget/BarChart";
import ValueWidget from "../Widget/Value";

const styles = {};

class DashboardGrid extends React.Component {
    state = {};

    render() {
        const {classes} = this.props;
        const dashboard = this.props.dashboard;
        const onClose = this.props.onClose;
        const content = this.props.content;
        const grid = this.props.dashboard.grid;
        if (grid === undefined) return <div className={classes.contentWrapper}></div>;
        return (
            <div className={classes.contentWrapper}>
                {Object.keys(this.props.data).length > 0 ?
                    <Grid container spacing={16} style={{margin: 0, width: '100%'}}>
                        {grid.items && grid.items.map((widget, key) => {
                            let wvalue = {};
                            if (widget.type === 'numeric') {
                                wvalue = this.props.data[widget.value] && this.props.data[widget.value].total;
                            }
                            if (widget.type === 'line') {
                                wvalue = {};
                                widget.value.map((key) => {
                                    wvalue[key] = this.props.data[key] ? this.props.data[key].daily : [];
                                    return;
                                });
                            }
                            if (widget.type === 'bar') {
                                wvalue = {};
                                widget.value.map((key) => {
                                    wvalue[key] = this.props.data[key] ? this.props.data[key].total : 0
                                    return;
                                });
                            }
                            return (
                                <Grid key={key} item xs={widget.size}>
                                    {widget.type === 'numeric' &&
                                    <NumericWidget
                                        widget={widget}
                                        value={wvalue}
                                        handleEditWidget={this.props.handleEditWidget}
                                        index={key}
                                    />
                                    }
                                    {widget.type === 'line' &&
                                    <LineChartWidget
                                        widget={widget}
                                        value={wvalue}
                                        handleEditWidget={this.props.handleEditWidget}
                                        index={key}
                                    />
                                    }
                                    {widget.type === 'bar' &&
                                    <BarChartWidget
                                        widget={widget}
                                        value={wvalue}
                                        handleEditWidget={this.props.handleEditWidget}
                                        index={key}
                                    />
                                    }
                                    {widget.type === 'value' &&
                                    <ValueWidget
                                        widget={widget}
                                        data={this.props.data}
                                        sums={this.props.sums}
                                        handleEditWidget={this.props.handleEditWidget}
                                        index={key}
                                    />
                                    }
                                </Grid>
                            );
                        })}
                    </Grid> : null}
            </div>
        );
    }
}

DashboardGrid.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardGrid);
